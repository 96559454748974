import styled from 'styled-components';

export const RuletaLiveStreaming = styled.section`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: grid;
  justify-content: center;
  align-items: center;
`;
