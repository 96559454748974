import { RUTAS } from '../../../../enums';
import { useAuthContext } from '../../../../providers/AuthProvider';
import * as S from './Navegador.styled';
import { DatosDeUsuario, NavLink } from './components';

interface IProps {
  isOpen: boolean;
}
const Navegador = ({ isOpen }: IProps) => {
  const { logout } = useAuthContext();

  return (
    <S.Navegador isOpen={isOpen}>
      <DatosDeUsuario />
      <NavLink to={RUTAS.LOBBY}>Lobby</NavLink>
      <NavLink rolRequerido={2} to={RUTAS.PANEL}>
        Panel de control
      </NavLink>
      <NavLink rolRequerido={2} to={RUTAS.JUGADORES}>
        Jugadores
      </NavLink>
      <NavLink onClick={() => logout()}>Cerrar sesión</NavLink>
    </S.Navegador>
  );
};

export default Navegador;
