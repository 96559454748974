import { createContext, useContext, useState } from 'react';
import { Dialog } from '../components';

type DialogType = {
  messages: string[];
  buttons: {
    text: string;
    onClick: () => void;
  }[];
};

type StateType = {
  dialog: DialogType | null;
  setDialog: (dialog: DialogType | null) => void;
};

const initialState: StateType = {
  dialog: {
    messages: [],
    buttons: [],
  },
  setDialog: () => {},
};

export const DialogContext = createContext(initialState);

interface DialogProviderProps {
  children: React.ReactNode;
}
const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialog, setDialog] = useState<DialogType | null>(null);

  return (
    <DialogContext.Provider value={{ dialog, setDialog }}>
      {children}
      <Dialog />
    </DialogContext.Provider>
  );
};

export default DialogProvider;

export const useDialogContext = () => {
  const useDialogContext = useContext(DialogContext);

  if (!useDialogContext) throw new Error('useDialogContext debe ser implementado dentro del provider');

  return useDialogContext;
};
