import { useMeeting } from '@videosdk.live/react-sdk';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '../../../../components';
import { setCargando, setJuegoState } from '../../../../redux/slices';
import { IAppStore } from '../../../../redux/store';
import * as S from './AccionesSala.styled';

const AccionesSala = () => {
  const { streaming } = useSelector((store: IAppStore) => store.juego);
  const dispatch = useDispatch();

  const { participants, join, leave, enableWebcam } = useMeeting({
    onMeetingJoined: () => {
      dispatch(setJuegoState({ streaming: true }));
      dispatch(setCargando(false));
    },
    onMeetingLeft: () => {
      dispatch(setJuegoState({ streaming: false }));
      dispatch(setCargando(false));
    },
    onError: () => {
      leave();
      dispatch(setCargando(false));
    },
  });

  useEffect(() => {
    const jugadores = [...participants.keys()];
    dispatch(setJuegoState({ jugadores }));
  }, [dispatch, participants]);

  const conectarSala = () => {
    dispatch(setCargando(true));
    join();
  };

  const desconectarSala = () => {
    dispatch(setCargando(true));
    leave();
  };

  return (
    <S.AccionesSala>
      <S.Sala>
        <Flex display='flex' gap='1rem'>
          <S.ConectarSala onClick={!streaming ? conectarSala : desconectarSala}>
            {!streaming ? 'Conectar' : 'Desconectar'}
          </S.ConectarSala>
          <S.AbrirCamara disabled={streaming} onClick={() => enableWebcam()}>
            Abrir camara
          </S.AbrirCamara>
        </Flex>
      </S.Sala>
    </S.AccionesSala>
  );
};

export default AccionesSala;
