import * as S from './BotonApuesta.styled';

interface IProps {
  id: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const BotonApuesta = ({ id, onClick, children }: IProps) => {
  const clickHandler = () => {
    if (onClick) onClick();
  };
  return (
    <S.BotonApuesta id={id} onClick={clickHandler}>
      {children}
    </S.BotonApuesta>
  );
};

export default BotonApuesta;
