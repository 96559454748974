import { MeetingProvider } from '@videosdk.live/react-sdk';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import { DialogProvider, TimerProvider, AuthProvider } from './providers';
import store from './redux/store';

const meeting = {
  config: {
    meetingId: 'cd8h-ncjx-j6hf',
    micEnabled: false,
    webcamEnabled: false,
    name: 'Ruleta',
  },
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJlNjBhMmY2ZS1mZmQwLTQyMDEtOTg2NS03Y2RkNWY2MGRhNjIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcwNzU5NTk4NCwiZXhwIjoxNzM5MTMxOTg0fQ.HtUiuwcv9EmeH5BPRRcZ-7qcF5cFUYQOSOJAZPD4rOg',
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <Router>
      <MeetingProvider {...meeting}>
        <TimerProvider>
          <DialogProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </DialogProvider>
        </TimerProvider>
      </MeetingProvider>
    </Router>
  </Provider>
);
