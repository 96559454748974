import { FichasDisponibles, Usuario } from '../../../';
import * as S from './DatosDeUsuario.styled';

const DatosDeUsuario = () => {
  return (
    <S.DatosDeUsuario>
      <Usuario />
      <FichasDisponibles />
    </S.DatosDeUsuario>
  );
};

export default DatosDeUsuario;
