import { useDispatch, useSelector } from 'react-redux';
import { ESTADOS } from '../../../../../../../../enums';
import { apostar } from '../../../../../../../../redux/slices/usuarioSlice';
import { IAppStore } from '../../../../../../../../redux/store';
import { BotonApuesta, FichaToken } from '../../components';

const botonApuestaConFichaSeleccionada = (id: string) => {
  return () => {
    const {
      usuario: { fichaSeleccionada: totalFichas, apuestas },
      juego: { estado },
    } = useSelector((store: IAppStore) => store);
    const dispatch = useDispatch();

    const fichasApostadas = apuestas[id];

    const clickHandler = () => {
      if (estado === ESTADOS.EN_CURSO) {
        let newApuestas = { ...apuestas };
        if (Object.hasOwn(apuestas, id)) {
          newApuestas[`${id}`] = apuestas[`${id}`] + totalFichas;
        } else {
          newApuestas[`${id}`] = totalFichas;
        }
        dispatch(apostar(newApuestas));
      }
    };

    return (
      <BotonApuesta id={id} onClick={clickHandler}>
        {fichasApostadas && <FichaToken fichasApostadas={fichasApostadas} />}
      </BotonApuesta>
    );
  };
};

export default botonApuestaConFichaSeleccionada;
