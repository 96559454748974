import styled from 'styled-components';
import entrada from '../../assets/entrada_bg.jpg';
import { pageStyle } from '../../styles';

export const Lobby = styled.main`
  ${pageStyle};
  background-image: url(${entrada});
`;

export const Img = styled.img`
  height: 40vh;
  object-fit: contain;
  cursor: pointer;
`;
