import { useSelector } from 'react-redux';
import { IAppStore } from '../../../../redux/store';
import * as S from './Usuario.styled';

const Usuario = () => {
  const { usuario } = useSelector((store: IAppStore) => store.usuario);
  return <S.Usuario>{usuario}</S.Usuario>;
};

export default Usuario;
