import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fichero as fichasDisponibles } from '../../data';
import { IApuestas, IFicha, IUsuario } from '../../models';
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '../../utils';

export interface IUsuarioState extends IUsuario {
  fichaSeleccionada: number;
  fichero: IFicha[];
  apuestas: IApuestas;
  conectado: boolean;
  tiempoDisponible: number;
}

const initialState: IUsuarioState = {
  _id: '',
  usuario: '',
  nombre: '',
  email: '',
  telefono: '',
  rol: 1,
  nivel: 1,
  fichas: 0,
  fichaSeleccionada: 0,
  fichero: [],
  apuestas: {},
  conectado: false,
  tiempoDisponible: 1800000,
};

const usuarioSlice = createSlice({
  name: 'usuarioSlice',
  initialState: getLocalStorage('usuario') ? getLocalStorage('usuario') : initialState,
  reducers: {
    setUsuarioData: (state: IUsuarioState, action) => {
      const fichero = fichasDisponibles[action.payload.nivel];
      const fichaSeleccionada = fichero[0].valor;
      const newState: IUsuarioState = {
        ...state,
        ...action.payload,
        fichero,
        fichaSeleccionada,
      };
      setLocalStorage('usuario', newState);
      return newState;
    },
    clearUsuarioState: () => {
      clearLocalStorage();
      return initialState;
    },
    seleccionarFicha: (state, action) => {
      const newState = {
        ...state,
        fichaSeleccionada: action.payload,
      };
      setLocalStorage('usuario', newState);
      return newState;
    },
    apostar: (state: IUsuarioState, action: PayloadAction<IApuestas>) => {
      if (state.fichas - state.fichaSeleccionada < 0) {
        return state;
      }
      const newState: IUsuarioState = {
        ...state,
        fichas: state.fichas - state.fichaSeleccionada,
        apuestas: action.payload,
      };
      setLocalStorage('usuario', newState);
      return newState;
    },
    limpiarApuestas: (state: IUsuarioState) => {
      const newState: IUsuarioState = { ...state, apuestas: {} };
      setLocalStorage('usuario', newState);
      return newState;
    },
    setFichas: (state, action: PayloadAction<{ fichas: number }>) => {
      const newState: IUsuarioState = { ...state, fichas: action.payload.fichas };
      setLocalStorage('usuario', newState);
      return newState;
    },
    setTiempoDisponible: (state, action: PayloadAction<number>) => {
      const newState: IUsuarioState = { ...state, tiempoDisponible: action.payload };
      setLocalStorage('usuario', newState);
      return newState;
    },
    resetTiempoDisponible: state => {
      const newState: IUsuarioState = { ...state, tiempoDisponible: initialState.tiempoDisponible };
      setLocalStorage('usuario', newState);
      return newState;
    },
  },
});

export default usuarioSlice;
export const {
  setUsuarioData,
  clearUsuarioState,
  seleccionarFicha,
  apostar,
  limpiarApuestas,
  setFichas,
  setTiempoDisponible,
  resetTiempoDisponible,
} = usuarioSlice.actions;
