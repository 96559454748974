import { INumero } from '../../../../../../../../../../models';
import * as S from './Numero.styled';

interface IProps {
  numero: INumero;
  onClick: (numero: INumero) => void;
}
const Numero = ({ numero, onClick }: IProps) => {
  const clickHandler = () => {
    onClick(numero);
  };
  return <S.Numero numero={numero} onClick={clickHandler}>{`${numero.valor}`}</S.Numero>;
};

export default Numero;
