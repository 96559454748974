import styled, { css } from 'styled-components';

const ficha5 = css`
  background-image: url('https://i.imgur.com/J69re1v.png');
`;
const ficha10 = css`
  background-image: url('https://i.imgur.com/IpM491u.png');
`;
const ficha25 = css`
  background-image: url('https://i.imgur.com/SqCvvgu.png');
`;
const ficha50 = css`
  background-image: url('https://i.imgur.com/3tSEOXT.png');
`;
const ficha100 = css`
  background-image: url('https://i.imgur.com/ZgO0iCE.png');
`;
const ficha500 = css`
  background-image: url('https://i.imgur.com/ZgO0iCE.png');
`;
const ficha1000 = css`
  background-image: url('https://i.imgur.com/ZgO0iCE.png');
`;
const ficha2000 = css`
  background-image: url('https://i.imgur.com/ZgO0iCE.png');
`;
const ficha3000 = css`
  background-image: url('https://i.imgur.com/ZgO0iCE.png');
`;

const animacion = css`
  animation: plop 400ms;
  @keyframes plop {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

interface IFichaTokenProps {
  fichasApostadas: number;
  animar: boolean;
}
export const FichaToken = styled.div<IFichaTokenProps>`
  width: 100%;
  height: 100%;
  scale: 1.2;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0.47rem;

  ${({ fichasApostadas }: IFichaTokenProps) =>
    fichasApostadas >= 3000
      ? `${ficha3000}`
      : fichasApostadas >= 2000
      ? `${ficha2000}`
      : fichasApostadas >= 1000
      ? `${ficha1000}`
      : fichasApostadas >= 500
      ? `${ficha500}`
      : fichasApostadas >= 100
      ? `${ficha100}`
      : fichasApostadas >= 50
      ? `${ficha50}`
      : fichasApostadas >= 25
      ? `${ficha25}`
      : fichasApostadas >= 10
      ? `${ficha10}`
      : `${ficha5}`}

  ${({ animar }: IFichaTokenProps) => animar && `${animacion}`}
`;
