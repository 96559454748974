import * as S from './Button.styled';

interface IProps {
  children: React.ReactNode;
  width?: string;
  padding?: string;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}
const Button = ({
  children,
  width,
  padding,
  margin,
  color,
  backgroundColor,
  onClick,
  isActive,
  disabled,
  type = 'button',
}: IProps) => {
  return (
    <S.Button
      width={width}
      padding={padding}
      margin={margin}
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
      isActive={isActive}
      disabled={disabled}
      type={type}
    >
      {children}
    </S.Button>
  );
};

export default Button;
