import { configureStore } from '@reduxjs/toolkit';
import juegoSlice, { IJuegoState } from './slices/juegoSlice';
import uiSlice, { IUIState } from './slices/uiSlice';
import usuarioSlice, { IUsuarioState } from './slices/usuarioSlice';

export interface IAppStore {
  usuario: IUsuarioState;
  juego: IJuegoState;
  ui: IUIState;
}

const store = configureStore<IAppStore>({
  reducer: {
    usuario: usuarioSlice.reducer,
    juego: juegoSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export default store;
