import { IFicha } from '../models';

interface IFichero {
  [index: number]: IFicha[];
}
export const fichero: IFichero = {
  1: [
    {
      valor: 5,
      label: '5',
    },
    {
      valor: 10,
      label: '10',
    },
    {
      valor: 25,
      label: '25',
    },
    {
      valor: 50,
      label: '50',
    },
    {
      valor: 100,
      label: '100',
    },
  ],
  2: [
    {
      valor: 25,
      label: '25',
    },
    {
      valor: 50,
      label: '50',
    },
    {
      valor: 100,
      label: '100',
    },
    {
      valor: 500,
      label: '500',
    },
    {
      valor: 1000,
      label: '1000',
    },
  ],
  3: [
    {
      valor: 250,
      label: '250',
    },
    {
      valor: 500,
      label: '500',
    },
    {
      valor: 1000,
      label: '1000',
    },
    {
      valor: 2000,
      label: '2000',
    },
    {
      valor: 3000,
      label: '3000',
    },
  ],
};

// import { IFicha } from '../models';

// interface IFichero {
//   1: IFicha[];
//   2: IFicha[];
//   3: IFicha[];
// }
// export const fichero: IFichero = {
//   1: [
//     {
//       valor: 5,
//       label: '5',
//     },
//     {
//       valor: 10,
//       label: '10',
//     },
//     {
//       valor: 25,
//       label: '25',
//     },
//     {
//       valor: 50,
//       label: '50',
//     },
//     {
//       valor: 100,
//       label: '100',
//     },
//   ],
//   2: [
//     {
//       valor: 25,
//       label: '25',
//     },
//     {
//       valor: 50,
//       label: '50',
//     },
//     {
//       valor: 100,
//       label: '100',
//     },
//     {
//       valor: 500,
//       label: '500',
//     },
//     {
//       valor: 1000,
//       label: '1000',
//     },
//   ],
//   3: [
//     {
//       valor: 250,
//       label: '250',
//     },
//     {
//       valor: 500,
//       label: '500',
//     },
//     {
//       valor: 1000,
//       label: '1000',
//     },
//     {
//       valor: 2000,
//       label: '2000',
//     },
//     {
//       valor: 3000,
//       label: '3000',
//     },
//   ],
// };
