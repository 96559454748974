import styled from 'styled-components';

export const GrillaApuestas = styled.section`
  position: absolute;
  bottom: 5%;
  right: 1.7%;
  display: grid;
  grid-template-columns: repeat(27, 2.75vh);
  grid-template-rows: repeat(10, 2.75vh);
  grid-template-areas:
    '.        comb-0-1-2-3   comb-1-2-3    comb-1-2-3-4-5-6        comb-4-5-6       comb-4-5-6-7-8-9       comb-7-8-9        comb-7-8-9-10-11-12        comb-10-11-12        comb-10-11-12-13-14-15        comb-13-14-15        comb-13-14-15-16-17-18        comb-16-17-18        comb-16-17-18-19-20-21        comb-19-20-21        comb-19-20-21-22-23-24        comb-22-23-24        comb-22-23-24-25-26-27        comb-25-26-27        comb-25-26-27-28-29-30        comb-28-29-30        comb-28-29-30-31-32-33        comb-31-32-33        comb-31-32-33-34-35-36        comb-34-35-36        .        .'
    'nume-0   comb-0-3       nume-3        comb-3-6        nume-6        comb-6-9        nume-9        comb-9-12        nume-12        comb-12-15        nume-15        comb-15-18        nume-18        comb-18-21        nume-21        comb-21-24        nume-24        comb-24-27        nume-27        comb-27-30        nume-30        comb-30-33        nume-33        comb-33-36        nume-36        .        chan-3erCol'
    'nume-0   comb-0-2-3     comb-2-3      comb-2-3-5-6        comb-5-6        comb-5-6-8-9        comb-8-9        comb-8-9-11-12        comb-11-12        comb-11-12-14-15        comb-14-15        comb-14-15-17-18        comb-17-18        comb-17-18-20-21        comb-20-21        comb-20-21-23-24        comb-23-24        comb-23-24-26-27        comb-26-27        comb-26-27-29-30        comb-29-30        comb-29-30-32-33        comb-32-33        comb-32-33-35-36        comb-35-36        .        .'
    'nume-0   comb-0-2       nume-2        comb-2-5        nume-5        comb-5-8        nume-8        comb-8-11        nume-11        comb-11-14        nume-14        comb-14-17        nume-17        comb-17-20        nume-20        comb-20-23        nume-23        comb-23-26        nume-26        comb-26-29        nume-29        comb-29-32        nume-32        comb-32-35        nume-35        .        chan-2daCol'
    'nume-0   comb-0-1-2     comb-1-2      comb-1-2-4-5        comb-4-5        comb-4-5-7-8        comb-7-8        comb-7-8-10-11        comb-10-11        comb-10-11-13-14        comb-13-14        comb-13-14-16-17        comb-16-17        comb-16-17-19-20        comb-19-20        comb-19-20-22-23        comb-22-23        comb-22-23-25-26        comb-25-26        comb-25-26-28-29        comb-28-29        comb-28-29-31-32        comb-31-32        comb-31-32-34-35        comb-34-35        .        .'
    'nume-0   comb-0-1       nume-1        comb-1-4        nume-4        comb-4-7        nume-7        comb-7-10        nume-10        comb-10-13        nume-13        comb-13-16        nume-16        comb-16-19        nume-19        comb-19-22        nume-22        comb-22-25        nume-25        comb-25-28        nume-28        comb-28-31        nume-31        comb-31-34        nume-34        .        chan-1erCol'
    '.        .              .             .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .'
    '.        .              chan-1erDoc   chan-1erDoc        chan-1erDoc        chan-1erDoc        chan-1erDoc        chan-1erDoc        chan-1erDoc        .        chan-2daDoc        chan-2daDoc        chan-2daDoc        chan-2daDoc        chan-2daDoc        chan-2daDoc        chan-2daDoc        .        chan-3erDoc        chan-3erDoc        chan-3erDoc        chan-3erDoc        chan-3erDoc        chan-3erDoc        chan-3erDoc        .        .'
    '.        .              .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .        .'
    '.        .              chan-menor        .        chan-impar        chan-impar        chan-impar        chan-impar        chan-impar        .        chan-negro        chan-negro        chan-negro        .        chan-rojo        chan-rojo        chan-rojo        .        chan-par        chan-par        chan-par        chan-par        chan-par        .        chan-mayor        .        .';
  gap: 2px;

  @media (width < 1024px) {
    top: 1.5%;
    left: 4.3%;
    grid-template-columns: repeat(10, 2.75vh);
    grid-template-rows: repeat(27, 2.75vh);
    grid-template-areas:
      '.           .  .            .  nume-0       nume-0            nume-0       nume-0            nume-0       .'
      '.           .  .            .  comb-0-1     comb-0-1-2        comb-0-2     comb-0-2-3        comb-0-3     comb-0-1-2-3'
      'chan-menor  .  chan-1erDoc  .  nume-1       comb-1-2          nume-2       comb-2-3          nume-3       comb-1-2-3'
      '.           .  chan-1erDoc  .  comb-1-4     comb-1-2-4-5      comb-2-5     comb-2-3-5-6      comb-3-6     comb-1-2-3-4-5-6'
      'chan-impar  .  chan-1erDoc  .  nume-4       comb-4-5          nume-5       comb-5-6          nume-6       comb-4-5-6'
      'chan-impar  .  chan-1erDoc  .  comb-4-7     comb-4-5-7-8      comb-5-8     comb-5-6-8-9      comb-6-9     comb-4-5-6-7-8-9'
      'chan-impar  .  chan-1erDoc  .  nume-7       comb-7-8          nume-8       comb-8-9          nume-9       comb-7-8-9'
      'chan-impar  .  chan-1erDoc  .  comb-7-10    comb-7-8-10-11    comb-8-11    comb-8-9-11-12    comb-9-12    comb-7-8-9-10-11-12'
      'chan-impar  .  chan-1erDoc  .  nume-10      comb-10-11        nume-11      comb-11-12        nume-12      comb-10-11-12'
      '.           .  .            .  comb-10-13   comb-10-11-13-14  comb-11-14   comb-11-12-14-15  comb-12-15   comb-10-11-12-13-14-15'
      'chan-negro  .  chan-2daDoc  .  nume-13      comb-13-14        nume-14      comb-14-15        nume-15      comb-13-14-15'
      'chan-negro  .  chan-2daDoc  .  comb-13-16   comb-13-14-16-17  comb-14-17   comb-14-15-17-18  comb-15-18   comb-13-14-15-16-17-18'
      'chan-negro  .  chan-2daDoc  .  nume-16      comb-16-17        nume-17      comb-17-18        nume-18      comb-16-17-18'
      '.           .  chan-2daDoc  .  comb-16-19   comb-16-17-19-20  comb-17-20   comb-17-18-20-21  comb-18-21   comb-16-17-18-19-20-21'
      'chan-rojo   .  chan-2daDoc  .  nume-19      comb-19-20        nume-20      comb-20-21        nume-21      comb-19-20-21'
      'chan-rojo   .  chan-2daDoc  .  comb-19-22   comb-19-20-22-23  comb-20-23   comb-20-21-23-24  comb-21-24   comb-19-20-21-22-23-24'
      'chan-rojo   .  chan-2daDoc  .  nume-22      comb-22-23        nume-23      comb-23-24        nume-24      comb-22-23-24'
      '.           .  .            .  comb-22-25   comb-22-23-25-26  comb-23-26   comb-23-24-26-27  comb-24-27   comb-22-23-24-25-26-27'
      'chan-par    .  chan-3erDoc  .  nume-25      comb-25-26        nume-26      comb-26-27        nume-27      comb-25-26-27'
      'chan-par    .  chan-3erDoc  .  comb-25-28   comb-25-26-28-29  comb-26-29   comb-26-27-29-30  comb-27-30   comb-25-26-27-28-29-30'
      'chan-par    .  chan-3erDoc  .  nume-28      comb-28-29        nume-29      comb-29-30        nume-30      comb-28-29-30'
      'chan-par    .  chan-3erDoc  .  comb-28-31   comb-28-29-31-32  comb-29-32   comb-29-30-32-33  comb-30-33   comb-28-29-30-31-32-33'
      'chan-par    .  chan-3erDoc  .  nume-31      comb-31-32        nume-32      comb-32-33        nume-33      comb-31-32-33'
      '.           .  chan-3erDoc  .  comb-31-34   comb-31-32-34-35  comb-32-35   comb-32-33-35-36  comb-33-36   comb-31-32-33-34-35-36'
      'chan-mayor  .  chan-3erDoc  .  nume-34      comb-34-35        nume-35      comb-35-36        nume-36      comb-34-35-36'
      '.           .  .            .  .            .                 .            .                 .            .'
      '.           .  .            .  chan-1erCol  .                 chan-2daCol  .                 chan-3erCol  .';
  }
`;
