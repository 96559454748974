import * as S from './Pano.styled';
import { GrillaApuestas, SistemaDeChances, SistemaDeNumeros } from './components';

const Pano = () => {
  return (
    <S.Pano>
      <S.GrillaNumeros>
        <SistemaDeNumeros />
        <SistemaDeChances />
        <GrillaApuestas />
      </S.GrillaNumeros>
    </S.Pano>
  );
};

export default Pano;
