import { io, Socket } from 'socket.io-client';
import { ESTADOS } from '../enums';
import { IApuestas, INumero } from '../models';

const socket: Socket = io(process.env.REACT_APP_RULETA_SERVER as string, {
  autoConnect: false,
  transports: ['polling'],
  upgrade: false,
});

const iniciar = () => {
  socket.emit('iniciar');
};

const parar = () => {
  socket.emit('parar');
};

const pausar = () => {
  socket.emit('pausar');
};

const servirNumero = (numero: INumero) => {
  socket.emit('servirNumero', numero);
};

const calcularPremio = (numeroServido: INumero, apuestas: IApuestas) => {
  socket.emit('calcularPremio', numeroServido, apuestas);
};

const setEstado = (estado: ESTADOS) => {
  socket.emit('setEstado', estado);
};

const limpiarApuestas = () => {
  socket.emit('limpiarApuestas');
};

export default socket;
export const socketService = { iniciar, parar, pausar, servirNumero, calcularPremio, setEstado, limpiarApuestas };
