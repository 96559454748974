import styled, { css } from 'styled-components';
import { fichaStyle } from '../../../../../../styles';

const fichaSeleccionadaStyle = css`
  filter: blur(0);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  transition: 75ms ease-in;
`;

interface IFichaProps {
  estaSeleccionada: boolean;
}
export const Ficha = styled.button<IFichaProps>`
  ${fichaStyle};
  filter: blur(2px);
  z-index: 30;

  ${({ estaSeleccionada }: IFichaProps) => estaSeleccionada && `${fichaSeleccionadaStyle}`};

  &#ficha-5 {
    background-image: url('https://i.imgur.com/J69re1v.png');
  }
  &#ficha-10 {
    background-image: url('https://i.imgur.com/IpM491u.png');
  }
  &#ficha-25 {
    background-image: url('https://i.imgur.com/SqCvvgu.png');
  }
  &#ficha-50 {
    background-image: url('https://i.imgur.com/3tSEOXT.png');
  }
  &#ficha-100 {
    background-image: url('https://i.imgur.com/ZgO0iCE.png');
  }
  &#ficha-250 {
    background-image: url('https://i.imgur.com/J69re1v.png');
  }
  &#ficha-500 {
    background-image: url('https://i.imgur.com/IpM491u.png');
  }
  &#ficha-1000 {
    background-image: url('https://i.imgur.com/SqCvvgu.png');
  }
  &#ficha-2000 {
    background-image: url('https://i.imgur.com/3tSEOXT.png');
  }
  &#ficha-3000 {
    background-image: url('https://i.imgur.com/ZgO0iCE.png');
  }
`;
