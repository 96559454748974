import { useDispatch, useSelector } from 'react-redux';
import { setIsDrawerOpen } from '../../../../redux/slices';
import { IAppStore } from '../../../../redux/store';
import * as S from './Burguer.styled';

const Burguer = () => {
  const { isDrawerOpen } = useSelector((store: IAppStore) => store.ui);
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(setIsDrawerOpen(!isDrawerOpen));
  };

  return (
    <S.Burguer>
      <div onClick={clickHandler}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </S.Burguer>
  );
};

export default Burguer;
