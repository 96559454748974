import { useSelector } from 'react-redux';
import { IFicha } from '../../../../models';
import { IAppStore } from '../../../../redux/store';
import * as S from './Fichero.styled';
import { Ficha } from './components';

const Fichero = () => {
  const { fichero } = useSelector((store: IAppStore) => store.usuario);

  return (
    <S.Fichero>
      <div>
        {fichero.map((ficha: IFicha) => (
          <Ficha key={ficha.valor} ficha={ficha} />
        ))}
      </div>
    </S.Fichero>
  );
};

export default Fichero;
