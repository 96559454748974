import * as S from './PanoServirNumero.styled';
import { SistemaNumerosParaServir } from './components';

const PanoServirNumero = () => {
  return (
    <S.PanoServirNumero>
      <SistemaNumerosParaServir />
    </S.PanoServirNumero>
  );
};

export default PanoServirNumero;
