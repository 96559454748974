import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiRuleta } from '../apis';
import { INumero } from '../models';
import { useDialogContext } from '../providers/DialogProvider';
import { useTimerContext } from '../providers/TimerProvider';
import { limpiarApuestas, setFichas } from '../redux/slices';
import { IAppStore } from '../redux/store';
import { socket, socketService } from '../services';

export const useSocket = () => {
  const {
    usuario: { _id, apuestas, fichas },
  } = useSelector((store: IAppStore) => store);
  const dispatch = useDispatch();
  const { setEnCurso, setEnPausa, setEnEspera } = useTimerContext();
  const { setDialog } = useDialogContext();

  // const onSetJuegoState = useCallback(
  //   async (juegoState: IJuegoState) => {
  //     dispatch(setJuegoState(juegoState));
  // if (juegoState.tiempoRestante === 0) dispatch(limpiarApuestas());
  // if (juegoState.tiempoRestante === 20) {
  // persistir las fichas ganadas en la db
  // try {
  //   await axios.post(
  //     `${server}/api/usuarios/actualizar`,
  //     { _id, fichas },
  //     {
  //       headers: { Authorization: `Bearer ${token}` },
  //     }
  //   );
  // } catch (e: any) {
  //   dispatch(setDialog({ messages: [e.response?.data?.payload], buttons: [] }));
  // }
  // }
  //   },
  //   [dispatch, _id, fichas, token]
  // );

  const onSetNumeroServido = useCallback(
    (numeroServido: INumero) => {
      setEnEspera();
      dispatch(limpiarApuestas());
      socketService.calcularPremio(numeroServido, apuestas);
    },
    [dispatch, apuestas, setEnEspera]
  );

  const onSetPremio = useCallback(
    async (numeroServido: INumero, premio: number) => {
      const fichasTotales = fichas + premio;
      try {
        await apiRuleta.post('/usuarios/actualizar', { _id, fichas: fichasTotales });
        dispatch(setFichas({ fichas: fichasTotales }));
        const messages = premio
          ? [`El número ganador es el ${numeroServido?.valor}`, 'Felicidades!', `Ha ganado ${premio} fichas!`]
          : [`El número ganador es el ${numeroServido?.valor}`];
        setDialog({
          messages,
          buttons: [
            {
              text: 'Continuar',
              onClick: () => {
                setDialog(null);
              },
            },
          ],
        });
      } catch (e: any) {
        setDialog({
          messages: [e.response?.data?.payload],
          buttons: [
            {
              text: 'Aceptar',
              onClick: () => {
                setDialog(null);
              },
            },
          ],
        });
      }
    },
    [dispatch, fichas, _id, setDialog]
  );

  const onSetEnCurso = useCallback(() => {
    setEnCurso();
  }, [setEnCurso]);

  const onSetEnPausa = useCallback(() => {
    setEnPausa();
  }, [setEnPausa]);

  const onSetEnEspera = useCallback(() => {
    setEnEspera();
  }, [setEnEspera]);

  const onSetLimpiarApuestas = useCallback(() => {
    dispatch(limpiarApuestas());
  }, [dispatch]);

  useEffect(() => {
    // socket.on('setJuegoState', onSetJuegoState);
    socket.on('setNumeroServido', onSetNumeroServido);
    socket.on('setPremio', onSetPremio);
    socket.on('setEnCurso', onSetEnCurso);
    socket.on('setEnPausa', onSetEnPausa);
    socket.on('setEnEspera', onSetEnEspera);
    socket.on('limpiarApuestas', onSetLimpiarApuestas);
    return () => {
      // socket.off('setJuegoState', onSetJuegoState);
      socket.off('setNumeroServido', onSetNumeroServido);
      socket.off('setPremio', onSetPremio);
      socket.off('setEnCurso', onSetEnCurso);
      socket.off('setEnPausa', onSetEnPausa);
      socket.off('setEnEspera', onSetEnEspera);
      socket.off('limpiarApuestas', onSetLimpiarApuestas);
    };
  }, [onSetNumeroServido, onSetPremio, onSetEnCurso, onSetEnPausa, onSetEnEspera, onSetLimpiarApuestas]);

  useEffect(() => {
    socket.connect();
    return () => {
      if (socket) socket.disconnect();
    };
  }, [dispatch]);

  return { socket };
};
