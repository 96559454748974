import { useState } from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Flex, Input } from '../../components';
import { RUTAS } from '../../enums';
import { useUsuarios } from '../../hooks';
import * as S from './AgregarFichas.styled';

const AgregarFichas = () => {
  const navigate = useNavigate();
  const [inputFichas, setFichas] = useState('');
  const { state: usuario } = useLocation();
  const [userFichas, setUserFichas] = useState(usuario.fichas);
  const { actualizarUsuario } = useUsuarios();

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.name === 'fichas' && setFichas(e.target.value);
  };

  const agregarFichas = async () => {
    setFichas('');
    setUserFichas(+userFichas + +inputFichas);
    await actualizarUsuario({ ...usuario, fichas: usuario.fichas + parseInt(inputFichas) });
  };

  const quitarFichas = async () => {
    if (usuario.fichas - parseInt(inputFichas) >= 0) {
      setFichas('');
      setUserFichas(+userFichas - +inputFichas);
      await actualizarUsuario({ ...usuario, fichas: usuario.fichas - parseInt(inputFichas) });
    }
  };

  const volver = () => {
    navigate(RUTAS.JUGADORES);
  };

  return (
    <S.AgregarFichas>
      <Card>
        <Flex display='flex' gap='1rem' flexDirection='column'>
          <Flex display='flex' flexDirection='row' alignItems='center' gap='1rem'>
            <BsArrowLeftCircle size={25} onClick={volver} title='Volver' />
            <S.Titulo>Agregar fichas</S.Titulo>
          </Flex>
          <p>Jugador: {usuario.nombre}</p>
          <p>Fichas: {userFichas}</p>
          <Input
            id='fichas'
            name='fichas'
            type='number'
            placeholder='Fichas'
            autoFocus
            value={inputFichas}
            onInput={inputHandler}
          />
          <Flex display='flex' gap='1rem' flexDirection='row'>
            <Button onClick={quitarFichas}>Quitar</Button>
            <Button onClick={agregarFichas}>Agregar</Button>
          </Flex>
        </Flex>
      </Card>
    </S.AgregarFichas>
  );
};

export default AgregarFichas;
