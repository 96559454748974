import { useState } from 'react';
import { Input, Logo } from '../../components';
import { useAuthContext } from '../../providers/AuthProvider';
import * as S from './Acceso.styled';
import { BotonIniciarSesion } from './components';

const Acceso = () => {
  const { login } = useAuthContext();
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ usuario, contrasena });
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'usuario') return setUsuario(e.target.value);
    if (e.target.name === 'contrasena') return setContrasena(e.target.value);
  };

  return (
    <S.Acceso>
      <S.Formulario onSubmit={submitHandler}>
        <Logo height='140px' centro />
        <Input
          id='usuario'
          name='usuario'
          placeholder='Usuario'
          type='text'
          value={usuario}
          autoFocus
          onInput={inputHandler}
        />
        <Input
          id='contrasena'
          name='contrasena'
          placeholder='Contraseña'
          type='password'
          value={contrasena}
          onInput={inputHandler}
        />
        <BotonIniciarSesion disabled={!usuario || !contrasena} />
      </S.Formulario>
    </S.Acceso>
  );
};

export default Acceso;
