import styled from 'styled-components';

interface IBotonApuestaProps {
  id: string;
}
export const BotonApuesta = styled.button<IBotonApuestaProps>`
  grid-area: ${({ id }: IBotonApuestaProps) => `${id}`};
  background-color: transparent;
  border: none;
  font-family: inherit;
  position: relative;
  cursor: pointer;
`;
