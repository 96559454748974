import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ESTADOS } from '../../enums';
import { getLocalStorage, setLocalStorage } from '../../utils';

export interface IJuegoState {
  tiempoRestante: number;
  estado: ESTADOS;
  premio: number | undefined;
  jugadores: string[];
  sala: string;
  streaming: boolean;
}

const initialState: IJuegoState = {
  tiempoRestante: 40,
  estado: ESTADOS.EN_ESPERA,
  premio: 0,
  jugadores: [],
  sala: '',
  streaming: false,
};

const juegoSlice = createSlice({
  name: 'juegoSlice',
  initialState: getLocalStorage('juego') ? getLocalStorage('juego') : initialState,
  reducers: {
    setJuegoState: (state: IJuegoState, action: PayloadAction<Partial<IJuegoState>>) => {
      const newState: IJuegoState = { ...state, ...action.payload };
      setLocalStorage('juego', newState);
      return newState;
    },
  },
});

export default juegoSlice;
export const { setJuegoState } = juegoSlice.actions;
