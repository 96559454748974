import { BsPauseCircle, BsPlayCircle, BsStopCircle } from 'react-icons/bs';
import { Flex } from '../../../../components';
import { ESTADOS } from '../../../../enums';
import { useTimerContext } from '../../../../providers/TimerProvider';
import { socketService } from '../../../../services';
import * as S from './AccionesRuleta.styled';

const AccionesRuleta = () => {
  const { estado, tiempoRestante } = useTimerContext();

  return (
    <S.AccionesRuleta>
      <Flex display='flex' flexDirection='row' gap='1rem' justifyContent='space-around'>
        <Flex display='flex' flexDirection='column' gap='1rem' justifyContent='space-between' alignItems='center'>
          <div>Tiempo restante:</div>
          <div>{tiempoRestante} seg.</div>
        </Flex>
        <Flex display='flex' flexDirection='column' gap='1rem' justifyContent='space-between' alignItems='center'>
          <div>Acciones:</div>
          <Flex display='flex' flexDirection='row' gap='1rem' justifyContent='space-between'>
            {estado !== ESTADOS.EN_CURSO ? (
              <BsPlayCircle size={25} onClick={socketService.iniciar} title='Iniciar' />
            ) : (
              <BsPauseCircle size={25} onClick={socketService.pausar} title='Pausar' />
            )}
            <BsStopCircle size={25} onClick={socketService.parar} title='Parar' />
          </Flex>
        </Flex>
      </Flex>
    </S.AccionesRuleta>
  );
};

export default AccionesRuleta;
