export enum RUTAS {
  ACCESO = '/acceso',
  LOBBY = '/',
  SALA = '/sala',
  ADMIN = '/admin',
  PANEL = '/admin/panel',
  JUGADORES = '/admin/jugadores',
  JUGADOR_CREAR = '/admin/jugador/crear',
  JUGADOR_EDITAR = '/admin/jugador/editar',
  JUGADOR_AGREGAR_FICHAS = '/admin/jugador/agregar-fichas',
}
