import styled from 'styled-components';

export const Pano = styled.section`
  justify-content: center;
  align-items: end;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (width < 1024px) {
    top: -16%;
    right: 0;
    left: inherit;
    transform: translateY(7.5%);
  }
`;

export const GrillaNumeros = styled.div`
  width: min-content;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(14, 5.5vh);
  grid-template-rows: repeat(5, 5.5vh);
  grid-template-areas:
    'numero-cero  numero-3    numero-6    numero-9    numero-12   numero-15    numero-18    numero-21    numero-24    numero-27   numero-30   numero-33   numero-36   tercer-col'
    'numero-cero  numero-2    numero-5    numero-8    numero-11   numero-14    numero-17    numero-20    numero-23    numero-26   numero-29   numero-32   numero-35   segunda-col'
    'numero-cero  numero-1    numero-4    numero-7    numero-10   numero-13    numero-16    numero-19    numero-22    numero-25   numero-28   numero-31   numero-34   primer-col'
    '.            primer-doc  primer-doc  primer-doc  primer-doc  segunda-doc  segunda-doc  segunda-doc  segunda-doc  tercer-doc  tercer-doc  tercer-doc  tercer-doc  .'
    '.            menor       impar       impar       impar       negro        negro        rojo         rojo         par         par         par         mayor       .';
  gap: 4px;
  user-select: none;
  scale: 0.8;

  @media (width < 1024px) {
    grid-template-columns: repeat(5, 5.5vh);
    grid-template-rows: repeat(14, 5.5vh);
    grid-template-areas:
      '.      .           numero-cero numero-cero numero-cero'
      'menor  primer-doc  numero-1    numero-2    numero-3'
      'impar  primer-doc  numero-4    numero-5    numero-6'
      'impar  primer-doc  numero-7    numero-8    numero-9'
      'impar  primer-doc  numero-10   numero-11   numero-12'
      'negro  segunda-doc numero-13   numero-14   numero-15'
      'negro  segunda-doc numero-16   numero-17   numero-18'
      'rojo   segunda-doc numero-19   numero-20   numero-21'
      'rojo   segunda-doc numero-22   numero-23   numero-24'
      'par    tercer-doc  numero-25   numero-26   numero-27'
      'par    tercer-doc  numero-28   numero-29   numero-30'
      'par    tercer-doc  numero-31   numero-32   numero-33'
      'mayor  tercer-doc  numero-34   numero-35   numero-36'
      '.      .           primer-col  segunda-col tercer-col';
  }

  > span {
    font-family: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    &.chance {
      padding: 0.2rem;
      background-color: black;
      color: white;
    }

    &#primer-doc {
      grid-area: primer-doc;
    }
    &#segunda-doc {
      grid-area: segunda-doc;
    }
    &#tercer-doc {
      grid-area: tercer-doc;
    }
    &#mayor {
      grid-area: mayor;
    }
    &#menor {
      grid-area: menor;
    }
    &#rojo {
      grid-area: rojo;
      background-color: red;
    }
    &#negro {
      grid-area: negro;
      background-color: black;
      color: white;
    }
    &#par {
      grid-area: par;

      @media (width < 1024px) {
        writing-mode: vertical-lr;
        text-orientation: upright;
      }
    }
    &#impar {
      grid-area: impar;

      @media (width < 1024px) {
        writing-mode: vertical-lr;
        text-orientation: upright;
      }
    }
    &#primer-col {
      grid-area: primer-col;
    }
    &#segunda-col {
      grid-area: segunda-col;
    }
    &#tercer-col {
      grid-area: tercer-col;
    }
  }
`;
