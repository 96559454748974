import { css } from 'styled-components';

export const drawerStyle = css`
  display: flex;
  height: 100vh;
  padding: 1rem;
  background-color: black;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
`;

export const numeroDefaultStyle = css`
  padding: 0.4rem 1rem;
  margin: 0.3rem;
`;

export const numeroSeleccionadoStyle = css`
  border: 2px solid yellow;
  ${numeroDefaultStyle};
`;

export const numeroStyle = css`
  border: 2px solid black;
  ${numeroDefaultStyle};
`;

export const numeroNegroStyle = css`
  background-color: black;
  color: white;
`;

export const numeroRojoStyle = css`
  background-color: red;
  color: white;
`;

export const numeroVerdeStyle = css`
  background-color: green;
  color: white;
`;

export const fichaStyle = css`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 100%;
  background-size: contain;
  font-family: inherit;
  cursor: pointer;
`;

export const fichaSeleccionadaStlye = css`
  background-color: red;
`;

export const color_gold_100_value = '#e0ad6c';

export const color_gold_200_value = '#302923';

export const color_black_100_value = '#090909';

export const color_white_100_value = '#ffffff';

export const color_ruby_100_value = '#fe0000';

export const color_ruby_200_value = '#ba0001';

export const color_ruby_300_value = '#860000';

export const defaultActiveStyle = css`
  :active {
    filter: brightness(50%);
  }
`;

export const pageStyle = css`
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const layoutStyle = css`
  padding-top: 2rem;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const inputStyle = css`
  color: ${color_white_100_value};
  border: 1px solid ${color_ruby_100_value};
  background-color: black;
  opacity: 90%;
  width: 100%;
  font-family: inherit;
  text-transform: none;
  padding: 0.5rem 1rem;

  ::placeholder {
    color: ${color_white_100_value};
    opacity: 1;
  }

  :focus {
    background-color: #302923;
    opacity: 90%;
  }
`;

export const primaryButtonStyle = css`
  height: fit-content;
  width: 100%;
  padding: 0.5rem 1rem;
  color: ${color_black_100_value};
  white-space: pre;
  border: 1px solid ${color_ruby_100_value};
  background-color: ${color_ruby_100_value};
  font-family: inherit;
  cursor: pointer;
  transition: 300ms;

  :hover {
    color: ${color_white_100_value};
    background-color: ${color_black_100_value};
  }
`;

export const secondaryButtonStyle = css`
  height: fit-content;
  width: 100%;
  padding: 0.5rem 1rem;
  color: ${color_white_100_value};
  white-space: pre;
  border: 1px solid ${color_ruby_100_value};
  background-color: ${color_black_100_value};
  font-family: inherit;
  cursor: pointer;
`;

export const iconStyle = css`
  color: ${color_ruby_100_value};
  cursor: pointer;
  transition: 300ms;

  :hover {
    color: white;
  }

  ${defaultActiveStyle};
`;
