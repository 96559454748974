import * as S from './Select.styled';

interface IProps {
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  children: React.ReactNode;
}
const Select = ({ id, name, onChange, children }: IProps) => {
  return (
    <S.Select id={id} name={name} onChange={onChange}>
      {children}
    </S.Select>
  );
};

export default Select;
