import * as S from './Card.styled';

interface IProps {
  children: React.ReactNode;
}
const Card = ({ children }: IProps) => {
  return <S.Card>{children}</S.Card>;
};

export default Card;
