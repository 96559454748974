import { Route, Routes } from 'react-router-dom';
import * as S from './App.styled';
import { Header, Loader } from './components';
import { RUTAS } from './enums';
import { GuardiaAutenticacion, GuardiaAutorizacion } from './guards';
import { Acceso, AgregarFichas, CrearCuenta, Jugadores, Lobby, NoEncontrado, Panel, Sala } from './pages';

// las fichas disponibles se restan al apostar, pero ese cambio no se ve impactado en la db
// si el jugador apostó antes de cerrar la ronda de apuestas y se ve interrumpido por un error o dc, se le paga y debera verlo al acceder

const App = () => {
  return (
    <S.App>
      <Header />
      <Routes>
        <Route path={RUTAS.ACCESO} element={<Acceso />} />
        <Route element={<GuardiaAutenticacion />}>
          <Route path={RUTAS.LOBBY} element={<Lobby />} />
          <Route path={RUTAS.SALA} element={<Sala />} />
          <Route path={RUTAS.ADMIN} element={<GuardiaAutorizacion />}>
            <Route path={RUTAS.PANEL} element={<Panel />} />
            <Route path={RUTAS.JUGADORES} element={<Jugadores />} />
            <Route path={RUTAS.JUGADOR_CREAR} element={<CrearCuenta />} />
            <Route path={RUTAS.JUGADOR_AGREGAR_FICHAS} element={<AgregarFichas />} />
          </Route>
        </Route>
        <Route path={'*'} element={<NoEncontrado />} />
      </Routes>
      <Loader />
    </S.App>
  );
};

export default App;
