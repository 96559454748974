const SistemaDeChances = () => {
  return (
    <>
      <span id='primer-doc' className='chance'>
        1er
      </span>
      <span id='segunda-doc' className='chance'>
        2da
      </span>
      <span id='tercer-doc' className='chance'>
        3ra
      </span>
      <span id='mayor' className='chance'>
        +
      </span>
      <span id='menor' className='chance'>
        -
      </span>
      <span id='rojo' className='chance'></span>
      <span id='negro' className='chance'></span>
      <span id='par' className='chance'>
        Par
      </span>
      <span id='impar' className='chance'>
        Impar
      </span>
      <span id='primer-col' className='chance'>
        2:1
      </span>
      <span id='segunda-col' className='chance'>
        2:1
      </span>
      <span id='tercer-col' className='chance'>
        2:1
      </span>
    </>
  );
};

export default SistemaDeChances;
