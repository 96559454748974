import styled from 'styled-components';
import { color_ruby_100_value, defaultActiveStyle } from '../../../../styles';

export const Burguer = styled.div`
  display: none;
  z-index: 10;

  > div {
    margin-top: 8px;
    cursor: pointer;
    transition: 300ms;

    span {
      border-bottom: 2px solid ${color_ruby_100_value};
      display: block;
      width: 50px;
      margin-bottom: 8px;
    }

    ${defaultActiveStyle};
  }

  @media (width < 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
