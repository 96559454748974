import { useEffect } from 'react';
import { FaPlus, FaTrash, FaUserEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Flex } from '../../components';
import { RUTAS } from '../../enums';
import { useUsuarios } from '../../hooks';
import { IUsuario } from '../../models';
import * as S from './Jugadores.styled';

const Jugadores = () => {
  const {
    getUsuarios,
    borrarUsuarioPorId,
    data: { payload: usuarios },
  } = useUsuarios();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getUsuarios();
    })();
  }, []);

  const crearJugador = () => {
    navigate(RUTAS.JUGADOR_CREAR);
  };

  const handleBorrar = async (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    await borrarUsuarioPorId(e.currentTarget.id);
    await getUsuarios();
  };

  const editarUsuario = (usuario: IUsuario) => {
    navigate(RUTAS.JUGADOR_EDITAR, { state: usuario });
  };

  const agregarFichas = (usuario: IUsuario) => {
    navigate(RUTAS.JUGADOR_AGREGAR_FICHAS, { state: usuario });
  };

  return (
    <S.Jugadores>
      <Card>
        <Flex display='flex' gap='1rem' flexDirection='column'>
          <S.Titulo>Jugadores</S.Titulo>
          <S.TablaJugadores>
            <table>
              <thead>
                <tr>
                  <th>Jugadores</th>
                  <th>Fichas</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {usuarios &&
                  usuarios.length >= 0 &&
                  usuarios.map((usuario: IUsuario) => (
                    <tr key={usuario._id}>
                      <td key={usuario._id}>{usuario.nombre}</td>
                      <td>{usuario.fichas}</td>
                      <td>
                        <FaUserEdit onClick={() => editarUsuario(usuario)} title='Editar jugador' />
                        <FaPlus onClick={() => agregarFichas(usuario)} title='Agregar fichas' />
                        <FaTrash id={usuario._id} title='Eliminar jugador' onClick={handleBorrar} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </S.TablaJugadores>
          <Flex display='flex'>
            <Button onClick={crearJugador}>Crear jugador</Button>
          </Flex>
        </Flex>
      </Card>
    </S.Jugadores>
  );
};

export default Jugadores;
