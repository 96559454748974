import styled from 'styled-components';
import { inputStyle } from '../../styles';

export const Input = styled.div`
  > label {
    display: block;
  }

  > input {
    ${inputStyle};
    border: none;
  }
`;
