import { useMeeting } from '@videosdk.live/react-sdk';
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiRuleta } from '../apis';
import { RUTAS } from '../enums';
import {
  clearUsuarioState,
  resetTiempoDisponible,
  setCargando,
  setJuegoState,
  setTiempoDisponible,
  setUsuarioData,
} from '../redux/slices';
import { IAppStore } from '../redux/store';
import { useDialogContext } from './DialogProvider';

type Credenciales = {
  usuario: string;
  contrasena: string;
};

type StateType = {
  token: string | null | undefined;
  login: (credenciales: Credenciales) => {};
  logout: () => {};
};

export const AuthContext = createContext<StateType | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}
const AuthProvider = ({ children }: AuthProviderProps) => {
  const {
    usuario: { tiempoDisponible },
  } = useSelector((store: IAppStore) => store);
  const [token, setToken] = useState<string | null>(null);
  const [isRetry, setIsRetry] = useState(false);
  const [isUserNotified, setIsUserNotified] = useState(false);
  const { setDialog } = useDialogContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leave } = useMeeting();

  useLayoutEffect(() => {
    const interceptor = apiRuleta.interceptors.request.use(config => {
      config.headers.Authorization = !isRetry && token ? `Bearer ${token}` : config.headers.Authorization;
      return config;
    });

    return () => {
      apiRuleta.interceptors.request.eject(interceptor);
    };
  }, [token, isRetry]);

  useLayoutEffect(() => {
    const interceptor = apiRuleta.interceptors.response.use(
      config => config,
      async error => {
        const ogRequest = error.config;
        console.log('Error:', error);
        if (error.status === 403) {
          try {
            // const response = await apiRuleta.get('/refresh-token');
            // setToken(response.data.token);
            // ogRequest.headers.Authorization = `Bearer ${response.data.token}`;
            setIsRetry(true);
            return apiRuleta(ogRequest);
          } catch {
            setToken(null);
          }
        }
        if (error.status > 400 && error.status < 500) {
          setDialog({
            messages: [error.response?.data?.payload],
            buttons: [
              {
                text: 'Continuar',
                onClick: () => {
                  setDialog(null);
                },
              },
            ],
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiRuleta.interceptors.response.eject(interceptor);
    };
  }, [token, setDialog]);

  const login = async ({ usuario, contrasena }: Credenciales) => {
    dispatch(setCargando(true));
    try {
      const {
        data: {
          payload: { token, ...userData },
        },
      } = await apiRuleta.post('/auth/login', {
        usuario,
        contrasena,
      });
      setToken(token);
      dispatch(setUsuarioData({ ...userData, conectado: true }));
      navigate(RUTAS.LOBBY);
    } catch (e: any) {
      setToken(null);
      setDialog({
        messages: [e.response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => {
              setDialog(null);
            },
          },
        ],
      });
    } finally {
      dispatch(setCargando(false));
    }
  };

  const logout = useCallback(async () => {
    setToken(null);
    dispatch(clearUsuarioState());
    dispatch(setJuegoState({ streaming: false }));
    leave();
  }, [leave, dispatch]);

  useEffect(() => {
    if (!token) return;

    const interval = setInterval(() => {
      if (tiempoDisponible <= 0) {
        logout();
        setDialog({
          messages: ['Tu sesión expiró', 'Volvé a loguearte para seguir jugando!'],
          buttons: [
            {
              text: 'Aceptar',
              onClick: () => {
                setDialog(null);
              },
            },
          ],
        });
      }
      if (tiempoDisponible === 300000 && !isUserNotified) {
        if (!isUserNotified) setIsUserNotified(true);
        setDialog({
          messages: [`Te quedan ${tiempoDisponible / 60000} minutos restantes.`, 'Querés mantener la sesión activa?'],
          buttons: [
            {
              onClick: () => {
                setDialog(null);
              },
              text: 'Cancelar',
            },
            {
              onClick: async () => {
                // await refreshToken();
                setIsUserNotified(false);
                dispatch(resetTiempoDisponible());
                setDialog(null);
              },
              text: 'Aceptar',
            },
          ],
        });
      }
      if (tiempoDisponible > 0) dispatch(setTiempoDisponible(tiempoDisponible - 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [token, tiempoDisponible, isUserNotified, logout, dispatch, setDialog]);

  return <AuthContext.Provider value={{ token, login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) throw new Error('useAuthContext debe ser implementado dentro del provider');

  return authContext;
};
