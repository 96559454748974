import styled from 'styled-components';
import { color_black_100_value, color_ruby_100_value, color_white_100_value } from '../../styles';

export const Card = styled.section`
  min-width: 80vw;
  padding: 1rem;
  border: 1px solid ${color_ruby_100_value};
  border-radius: 10px;
  background-color: ${color_black_100_value};
  color: ${color_white_100_value};
`;
