import styled from 'styled-components';
import entrada from '../../assets/entrada_bg.jpg';
import { iconStyle, pageStyle, primaryButtonStyle } from '../../styles';

export const AgregarFichas = styled.section`
  ${pageStyle};
  background-image: url(${entrada});

  svg {
    ${iconStyle};
  }
`;

export const Titulo = styled.h3`
  font-size: 2rem;
`;

export const Agregar = styled.button`
  ${primaryButtonStyle};
`;
