import styled from 'styled-components';
import entrada from '../../assets/entrada_bg.jpg';
import { iconStyle, pageStyle, primaryButtonStyle } from '../../styles';

export const CrearCuenta = styled.main`
  ${pageStyle};
  background-image: url(${entrada});

  svg {
    ${iconStyle};
  }
`;

export const Titulo = styled.h3`
  font-size: 2rem;
`;

export const CrearUsuario = styled.button`
  ${primaryButtonStyle};
`;

export const BorrarDatos = styled.button`
  ${primaryButtonStyle};
`;
