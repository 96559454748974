import * as S from './Input.styled';

interface IProps {
  id: string;
  name: string;
  placeholder: string;
  type?: string;
  value: string;
  autoFocus?: boolean;
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}
const Input = ({ id, name, placeholder, type = 'text', value, autoFocus, onInput, disabled }: IProps) => {
  return (
    <S.Input>
      <input
        id={id}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={onInput}
        autoComplete='off'
        disabled={disabled}
      />
    </S.Input>
  );
};

export default Input;
