import * as S from './Flex.styled';

interface IProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  display?: string;
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
}
const Flex = ({ children, ...rest }: IProps) => {
  return <S.Flex {...rest}>{children}</S.Flex>;
};

export default Flex;
