import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RUTAS } from '../../enums';
import { IAppStore } from '../../redux/store';

const GuardiaAutorizacion = () => {
  const { rol } = useSelector((store: IAppStore) => store.usuario);

  return rol === 2 ? <Outlet /> : <Navigate to={RUTAS.LOBBY} />;
};
export default GuardiaAutorizacion;
