import styled, { css } from 'styled-components';
import { color_black_100_value, color_ruby_100_value, color_white_100_value, defaultActiveStyle } from '../../styles';

const activeButtonStyle = css`
  background-color: ${color_black_100_value};
  color: ${color_white_100_value};
`;

interface IButtonProps {
  width?: string;
  padding?: string;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  isActive?: boolean;
}
export const Button = styled.button<IButtonProps>`
  height: fit-content;
  width: ${({ width }: IButtonProps) => (width ? width : '100%')};
  padding: ${({ padding }: IButtonProps) => (padding ? padding : '0.5rem 1rem')};
  color: ${({ color }: IButtonProps) => (color ? color : color_black_100_value)};
  white-space: pre;
  border: 1px solid ${color_ruby_100_value};
  background-color: ${({ backgroundColor }: IButtonProps) =>
    backgroundColor ? backgroundColor : color_ruby_100_value};
  font-family: inherit;
  cursor: pointer;
  transition: 300ms;

  ${({ isActive }: IButtonProps) => isActive && activeButtonStyle};

  :hover {
    color: ${color_white_100_value};
    background-color: ${color_black_100_value};
  }

  ${defaultActiveStyle};
`;
