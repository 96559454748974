import styled from 'styled-components';

interface IFlexProps {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  display?: string;
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
}
export const Flex = styled.div<IFlexProps>`
  width: ${({ width }: IFlexProps) => width};
  height: ${({ height }: IFlexProps) => height};
  padding: ${({ padding }: IFlexProps) => padding};
  margin: ${({ margin }: IFlexProps) => margin};
  display: ${({ display }: IFlexProps) => display};
  gap: ${({ gap }: IFlexProps) => gap};
  justify-content: ${({ justifyContent }: IFlexProps) => justifyContent};
  align-items: ${({ alignItems }: IFlexProps) => alignItems};
  flex-direction: ${({ flexDirection }: IFlexProps) => flexDirection};
`;
