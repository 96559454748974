import { Card, Flex } from '../../components';
import * as S from './Panel.styled';
import { AccionesRuleta, AccionesSala } from './components';
import { PanoServirNumero } from './components/AccionesRuleta/components';

const Panel = () => {
  return (
    <S.Panel>
      <Card>
        <Flex display='flex' flexDirection='column' gap='1rem'>
          <Flex display='flex' flexDirection='column' gap='1rem' justifyContent='space-around'>
            <AccionesRuleta />
            <PanoServirNumero />
          </Flex>
          <AccionesSala />
        </Flex>
      </Card>
    </S.Panel>
  );
};

export default Panel;
