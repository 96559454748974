import * as S from './FichasDisponibles.styled';
import { useSelector } from 'react-redux';
import { IAppStore } from '../../../../redux/store';

const FichasDisponibles = () => {
  const { fichas } = useSelector((store: IAppStore) => store.usuario);
  return <S.FichasDisponibles>${fichas}</S.FichasDisponibles>;
};

export default FichasDisponibles;
