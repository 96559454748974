import styled from 'styled-components';

interface ILogoProps {
  centro?: boolean;
  height?: string;
}
export const Logo = styled.div<ILogoProps>`
  height: ${({ height }: ILogoProps) => (height ? height : '140px')};
  display: flex;
  justify-content: ${({ centro }: ILogoProps) => (centro ? 'center' : 'flex-start')};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  > img {
    cursor: pointer;
  }
`;
