import * as S from './GrillaApuestas.styled';
import { botonApuestaConFichaSeleccionada } from './HOC';

const BotonApuestaNumero0 = botonApuestaConFichaSeleccionada('nume-0');
const BotonApuestaNumero1 = botonApuestaConFichaSeleccionada('nume-1');
const BotonApuestaNumero2 = botonApuestaConFichaSeleccionada('nume-2');
const BotonApuestaNumero3 = botonApuestaConFichaSeleccionada('nume-3');
const BotonApuestaNumero4 = botonApuestaConFichaSeleccionada('nume-4');
const BotonApuestaNumero5 = botonApuestaConFichaSeleccionada('nume-5');
const BotonApuestaNumero6 = botonApuestaConFichaSeleccionada('nume-6');
const BotonApuestaNumero7 = botonApuestaConFichaSeleccionada('nume-7');
const BotonApuestaNumero8 = botonApuestaConFichaSeleccionada('nume-8');
const BotonApuestaNumero9 = botonApuestaConFichaSeleccionada('nume-9');
const BotonApuestaNumero10 = botonApuestaConFichaSeleccionada('nume-10');
const BotonApuestaNumero11 = botonApuestaConFichaSeleccionada('nume-11');
const BotonApuestaNumero12 = botonApuestaConFichaSeleccionada('nume-12');
const BotonApuestaNumero13 = botonApuestaConFichaSeleccionada('nume-13');
const BotonApuestaNumero14 = botonApuestaConFichaSeleccionada('nume-14');
const BotonApuestaNumero15 = botonApuestaConFichaSeleccionada('nume-15');
const BotonApuestaNumero16 = botonApuestaConFichaSeleccionada('nume-16');
const BotonApuestaNumero17 = botonApuestaConFichaSeleccionada('nume-17');
const BotonApuestaNumero18 = botonApuestaConFichaSeleccionada('nume-18');
const BotonApuestaNumero19 = botonApuestaConFichaSeleccionada('nume-19');
const BotonApuestaNumero20 = botonApuestaConFichaSeleccionada('nume-20');
const BotonApuestaNumero21 = botonApuestaConFichaSeleccionada('nume-21');
const BotonApuestaNumero22 = botonApuestaConFichaSeleccionada('nume-22');
const BotonApuestaNumero23 = botonApuestaConFichaSeleccionada('nume-23');
const BotonApuestaNumero24 = botonApuestaConFichaSeleccionada('nume-24');
const BotonApuestaNumero25 = botonApuestaConFichaSeleccionada('nume-25');
const BotonApuestaNumero26 = botonApuestaConFichaSeleccionada('nume-26');
const BotonApuestaNumero27 = botonApuestaConFichaSeleccionada('nume-27');
const BotonApuestaNumero28 = botonApuestaConFichaSeleccionada('nume-28');
const BotonApuestaNumero29 = botonApuestaConFichaSeleccionada('nume-29');
const BotonApuestaNumero30 = botonApuestaConFichaSeleccionada('nume-30');
const BotonApuestaNumero31 = botonApuestaConFichaSeleccionada('nume-31');
const BotonApuestaNumero32 = botonApuestaConFichaSeleccionada('nume-32');
const BotonApuestaNumero33 = botonApuestaConFichaSeleccionada('nume-33');
const BotonApuestaNumero34 = botonApuestaConFichaSeleccionada('nume-34');
const BotonApuestaNumero35 = botonApuestaConFichaSeleccionada('nume-35');
const BotonApuestaNumero36 = botonApuestaConFichaSeleccionada('nume-36');

const BotonApuestaCombinacion01 = botonApuestaConFichaSeleccionada('comb-0-1');
const BotonApuestaCombinacion012 = botonApuestaConFichaSeleccionada('comb-0-1-2');
const BotonApuestaCombinacion02 = botonApuestaConFichaSeleccionada('comb-0-2');
const BotonApuestaCombinacion023 = botonApuestaConFichaSeleccionada('comb-0-2-3');
const BotonApuestaCombinacion03 = botonApuestaConFichaSeleccionada('comb-0-3');
const BotonApuestaCombinacion0123 = botonApuestaConFichaSeleccionada('comb-0-1-2-3');

const BotonApuestaCombinacion12 = botonApuestaConFichaSeleccionada('comb-1-2');
const BotonApuestaCombinacion23 = botonApuestaConFichaSeleccionada('comb-2-3');
const BotonApuestaCombinacion123 = botonApuestaConFichaSeleccionada('comb-1-2-3');

const BotonApuestaCombinacion14 = botonApuestaConFichaSeleccionada('comb-1-4');
const BotonApuestaCombinacion1245 = botonApuestaConFichaSeleccionada('comb-1-2-4-5');
const BotonApuestaCombinacion25 = botonApuestaConFichaSeleccionada('comb-2-5');
const BotonApuestaCombinacion2356 = botonApuestaConFichaSeleccionada('comb-2-3-5-6');
const BotonApuestaCombinacion36 = botonApuestaConFichaSeleccionada('comb-3-6');
const BotonApuestaCombinacion123456 = botonApuestaConFichaSeleccionada('comb-1-2-3-4-5-6');

const BotonApuestaCombinacion45 = botonApuestaConFichaSeleccionada('comb-4-5');
const BotonApuestaCombinacion56 = botonApuestaConFichaSeleccionada('comb-5-6');
const BotonApuestaCombinacion456 = botonApuestaConFichaSeleccionada('comb-4-5-6');

const BotonApuestaCombinacion47 = botonApuestaConFichaSeleccionada('comb-4-7');
const BotonApuestaCombinacion4578 = botonApuestaConFichaSeleccionada('comb-4-5-7-8');
const BotonApuestaCombinacion58 = botonApuestaConFichaSeleccionada('comb-5-8');
const BotonApuestaCombinacion5689 = botonApuestaConFichaSeleccionada('comb-5-6-8-9');
const BotonApuestaCombinacion69 = botonApuestaConFichaSeleccionada('comb-6-9');
const BotonApuestaCombinacion456789 = botonApuestaConFichaSeleccionada('comb-4-5-6-7-8-9');

const BotonApuestaCombinacion78 = botonApuestaConFichaSeleccionada('comb-7-8');
const BotonApuestaCombinacion89 = botonApuestaConFichaSeleccionada('comb-8-9');
const BotonApuestaCombinacion789 = botonApuestaConFichaSeleccionada('comb-7-8-9');

const BotonApuestaCombinacion710 = botonApuestaConFichaSeleccionada('comb-7-10');
const BotonApuestaCombinacion781011 = botonApuestaConFichaSeleccionada('comb-7-8-10-11');
const BotonApuestaCombinacion811 = botonApuestaConFichaSeleccionada('comb-8-11');
const BotonApuestaCombinacion891112 = botonApuestaConFichaSeleccionada('comb-8-9-11-12');
const BotonApuestaCombinacion912 = botonApuestaConFichaSeleccionada('comb-9-12');
const BotonApuestaCombinacion789101112 = botonApuestaConFichaSeleccionada('comb-7-8-9-10-11-12');

const BotonApuestaCombinacion1011 = botonApuestaConFichaSeleccionada('comb-10-11');
const BotonApuestaCombinacion1112 = botonApuestaConFichaSeleccionada('comb-11-12');
const BotonApuestaCombinacion101112 = botonApuestaConFichaSeleccionada('comb-10-11-12');

const BotonApuestaCombinacion1013 = botonApuestaConFichaSeleccionada('comb-10-13');
const BotonApuestaCombinacion10111314 = botonApuestaConFichaSeleccionada('comb-10-11-13-14');
const BotonApuestaCombinacion1114 = botonApuestaConFichaSeleccionada('comb-11-14');
const BotonApuestaCombinacion11121415 = botonApuestaConFichaSeleccionada('comb-11-12-14-15');
const BotonApuestaCombinacion1215 = botonApuestaConFichaSeleccionada('comb-12-15');
const BotonApuestaCombinacion101112131415 = botonApuestaConFichaSeleccionada('comb-10-11-12-13-14-15');

const BotonApuestaCombinacion1314 = botonApuestaConFichaSeleccionada('comb-13-14');
const BotonApuestaCombinacion1415 = botonApuestaConFichaSeleccionada('comb-14-15');
const BotonApuestaCombinacion131415 = botonApuestaConFichaSeleccionada('comb-13-14-15');

const BotonApuestaCombinacion1316 = botonApuestaConFichaSeleccionada('comb-13-16');
const BotonApuestaCombinacion13141617 = botonApuestaConFichaSeleccionada('comb-13-14-16-17');
const BotonApuestaCombinacion1417 = botonApuestaConFichaSeleccionada('comb-14-17');
const BotonApuestaCombinacion14151718 = botonApuestaConFichaSeleccionada('comb-14-15-17-18');
const BotonApuestaCombinacion1518 = botonApuestaConFichaSeleccionada('comb-15-18');
const BotonApuestaCombinacion131415161718 = botonApuestaConFichaSeleccionada('comb-13-14-15-16-17-18');

const BotonApuestaCombinacion1617 = botonApuestaConFichaSeleccionada('comb-16-17');
const BotonApuestaCombinacion1718 = botonApuestaConFichaSeleccionada('comb-17-18');
const BotonApuestaCombinacion161718 = botonApuestaConFichaSeleccionada('comb-16-17-18');

const BotonApuestaCombinacion1619 = botonApuestaConFichaSeleccionada('comb-16-19');
const BotonApuestaCombinacion16171920 = botonApuestaConFichaSeleccionada('comb-16-17-19-20');
const BotonApuestaCombinacion1720 = botonApuestaConFichaSeleccionada('comb-17-20');
const BotonApuestaCombinacion17182021 = botonApuestaConFichaSeleccionada('comb-17-18-20-21');
const BotonApuestaCombinacion1821 = botonApuestaConFichaSeleccionada('comb-18-21');
const BotonApuestaCombinacion161718192021 = botonApuestaConFichaSeleccionada('comb-16-17-18-19-20-21');

const BotonApuestaCombinacion1920 = botonApuestaConFichaSeleccionada('comb-19-20');
const BotonApuestaCombinacion2021 = botonApuestaConFichaSeleccionada('comb-20-21');
const BotonApuestaCombinacion192021 = botonApuestaConFichaSeleccionada('comb-19-20-21');

const BotonApuestaCombinacion1922 = botonApuestaConFichaSeleccionada('comb-19-22');
const BotonApuestaCombinacion19202223 = botonApuestaConFichaSeleccionada('comb-19-20-22-23');
const BotonApuestaCombinacion2023 = botonApuestaConFichaSeleccionada('comb-20-23');
const BotonApuestaCombinacion20212324 = botonApuestaConFichaSeleccionada('comb-20-21-23-24');
const BotonApuestaCombinacion2124 = botonApuestaConFichaSeleccionada('comb-21-24');
const BotonApuestaCombinacion192021222324 = botonApuestaConFichaSeleccionada('comb-19-20-21-22-23-24');

const BotonApuestaCombinacion2223 = botonApuestaConFichaSeleccionada('comb-22-23');
const BotonApuestaCombinacion2324 = botonApuestaConFichaSeleccionada('comb-23-24');
const BotonApuestaCombinacion222324 = botonApuestaConFichaSeleccionada('comb-22-23-24');

const BotonApuestaCombinacion2225 = botonApuestaConFichaSeleccionada('comb-22-25');
const BotonApuestaCombinacion22232526 = botonApuestaConFichaSeleccionada('comb-22-23-25-26');
const BotonApuestaCombinacion2326 = botonApuestaConFichaSeleccionada('comb-23-26');
const BotonApuestaCombinacion23242627 = botonApuestaConFichaSeleccionada('comb-23-24-26-27');
const BotonApuestaCombinacion2427 = botonApuestaConFichaSeleccionada('comb-24-27');
const BotonApuestaCombinacion222324252627 = botonApuestaConFichaSeleccionada('comb-22-23-24-25-26-27');

const BotonApuestaCombinacion2526 = botonApuestaConFichaSeleccionada('comb-25-26');
const BotonApuestaCombinacion2627 = botonApuestaConFichaSeleccionada('comb-26-27');
const BotonApuestaCombinacion252627 = botonApuestaConFichaSeleccionada('comb-25-26-27');

const BotonApuestaCombinacion2528 = botonApuestaConFichaSeleccionada('comb-25-28');
const BotonApuestaCombinacion25262829 = botonApuestaConFichaSeleccionada('comb-25-26-28-29');
const BotonApuestaCombinacion2629 = botonApuestaConFichaSeleccionada('comb-26-29');
const BotonApuestaCombinacion26272930 = botonApuestaConFichaSeleccionada('comb-26-27-29-30');
const BotonApuestaCombinacion2730 = botonApuestaConFichaSeleccionada('comb-27-30');
const BotonApuestaCombinacion252627282930 = botonApuestaConFichaSeleccionada('comb-25-26-27-28-29-30');

const BotonApuestaCombinacion2829 = botonApuestaConFichaSeleccionada('comb-28-29');
const BotonApuestaCombinacion2930 = botonApuestaConFichaSeleccionada('comb-29-30');
const BotonApuestaCombinacion282930 = botonApuestaConFichaSeleccionada('comb-28-29-30');

const BotonApuestaCombinacion2831 = botonApuestaConFichaSeleccionada('comb-28-31');
const BotonApuestaCombinacion28293132 = botonApuestaConFichaSeleccionada('comb-28-29-31-32');
const BotonApuestaCombinacion2932 = botonApuestaConFichaSeleccionada('comb-29-32');
const BotonApuestaCombinacion29303233 = botonApuestaConFichaSeleccionada('comb-29-30-32-33');
const BotonApuestaCombinacion3033 = botonApuestaConFichaSeleccionada('comb-30-33');
const BotonApuestaCombinacion282930313233 = botonApuestaConFichaSeleccionada('comb-28-29-30-31-32-33');

const BotonApuestaCombinacion3132 = botonApuestaConFichaSeleccionada('comb-31-32');
const BotonApuestaCombinacion3233 = botonApuestaConFichaSeleccionada('comb-32-33');
const BotonApuestaCombinacion313233 = botonApuestaConFichaSeleccionada('comb-31-32-33');

const BotonApuestaCombinacion3134 = botonApuestaConFichaSeleccionada('comb-31-34');
const BotonApuestaCombinacion31323435 = botonApuestaConFichaSeleccionada('comb-31-32-34-35');
const BotonApuestaCombinacion3235 = botonApuestaConFichaSeleccionada('comb-32-35');
const BotonApuestaCombinacion32333536 = botonApuestaConFichaSeleccionada('comb-32-33-35-36');
const BotonApuestaCombinacion3336 = botonApuestaConFichaSeleccionada('comb-33-36');
const BotonApuestaCombinacion313233343536 = botonApuestaConFichaSeleccionada('comb-31-32-33-34-35-36');

const BotonApuestaCombinacion3435 = botonApuestaConFichaSeleccionada('comb-34-35');
const BotonApuestaCombinacion3536 = botonApuestaConFichaSeleccionada('comb-35-36');
const BotonApuestaCombinacion343536 = botonApuestaConFichaSeleccionada('comb-34-35-36');

const BotonApuestaPar = botonApuestaConFichaSeleccionada('chan-par');
const BotonApuestaImpar = botonApuestaConFichaSeleccionada('chan-impar');
const BotonApuestaMenor = botonApuestaConFichaSeleccionada('chan-menor');
const BotonApuestaMayor = botonApuestaConFichaSeleccionada('chan-mayor');
const BotonApuestaRojo = botonApuestaConFichaSeleccionada('chan-rojo');
const BotonApuestaNegro = botonApuestaConFichaSeleccionada('chan-negro');
const BotonApuesta1er = botonApuestaConFichaSeleccionada('chan-1erDoc');
const BotonApuesta2da = botonApuestaConFichaSeleccionada('chan-2daDoc');
const BotonApuesta3er = botonApuestaConFichaSeleccionada('chan-3erDoc');
const BotonApuesta1erCol = botonApuestaConFichaSeleccionada('chan-1erCol');
const BotonApuesta2daCol = botonApuestaConFichaSeleccionada('chan-2daCol');
const BotonApuesta3erCol = botonApuestaConFichaSeleccionada('chan-3erCol');

const GrillaApuestas = () => {
  return (
    <S.GrillaApuestas>
      <BotonApuestaNumero0 />
      <BotonApuestaNumero1 />
      <BotonApuestaNumero2 />
      <BotonApuestaNumero3 />
      <BotonApuestaNumero4 />
      <BotonApuestaNumero5 />
      <BotonApuestaNumero6 />
      <BotonApuestaNumero7 />
      <BotonApuestaNumero8 />
      <BotonApuestaNumero9 />
      <BotonApuestaNumero10 />
      <BotonApuestaNumero11 />
      <BotonApuestaNumero12 />
      <BotonApuestaNumero13 />
      <BotonApuestaNumero14 />
      <BotonApuestaNumero15 />
      <BotonApuestaNumero16 />
      <BotonApuestaNumero17 />
      <BotonApuestaNumero18 />
      <BotonApuestaNumero19 />
      <BotonApuestaNumero20 />
      <BotonApuestaNumero21 />
      <BotonApuestaNumero22 />
      <BotonApuestaNumero23 />
      <BotonApuestaNumero24 />
      <BotonApuestaNumero25 />
      <BotonApuestaNumero26 />
      <BotonApuestaNumero27 />
      <BotonApuestaNumero28 />
      <BotonApuestaNumero29 />
      <BotonApuestaNumero30 />
      <BotonApuestaNumero31 />
      <BotonApuestaNumero32 />
      <BotonApuestaNumero33 />
      <BotonApuestaNumero34 />
      <BotonApuestaNumero35 />
      <BotonApuestaNumero36 />
      <BotonApuestaPar />
      <BotonApuestaImpar />
      <BotonApuestaMenor />
      <BotonApuestaMayor />
      <BotonApuestaRojo />
      <BotonApuestaNegro />
      <BotonApuesta1er />
      <BotonApuesta2da />
      <BotonApuesta3er />
      <BotonApuesta1erCol />
      <BotonApuesta2daCol />
      <BotonApuesta3erCol />
      <BotonApuestaCombinacion01 />
      <BotonApuestaCombinacion012 />
      <BotonApuestaCombinacion02 />
      <BotonApuestaCombinacion023 />
      <BotonApuestaCombinacion03 />
      <BotonApuestaCombinacion0123 />
      <BotonApuestaCombinacion12 />
      <BotonApuestaCombinacion23 />
      <BotonApuestaCombinacion123 />
      <BotonApuestaCombinacion14 />
      <BotonApuestaCombinacion1245 />
      <BotonApuestaCombinacion25 />
      <BotonApuestaCombinacion2356 />
      <BotonApuestaCombinacion36 />
      <BotonApuestaCombinacion123456 />
      <BotonApuestaCombinacion45 />
      <BotonApuestaCombinacion56 />
      <BotonApuestaCombinacion456 />
      <BotonApuestaCombinacion47 />
      <BotonApuestaCombinacion4578 />
      <BotonApuestaCombinacion58 />
      <BotonApuestaCombinacion5689 />
      <BotonApuestaCombinacion69 />
      <BotonApuestaCombinacion456789 />
      <BotonApuestaCombinacion78 />
      <BotonApuestaCombinacion89 />
      <BotonApuestaCombinacion789 />
      <BotonApuestaCombinacion710 />
      <BotonApuestaCombinacion781011 />
      <BotonApuestaCombinacion811 />
      <BotonApuestaCombinacion891112 />
      <BotonApuestaCombinacion912 />
      <BotonApuestaCombinacion789101112 />
      <BotonApuestaCombinacion1011 />
      <BotonApuestaCombinacion1112 />
      <BotonApuestaCombinacion101112 />
      <BotonApuestaCombinacion1013 />
      <BotonApuestaCombinacion10111314 />
      <BotonApuestaCombinacion1114 />
      <BotonApuestaCombinacion11121415 />
      <BotonApuestaCombinacion1215 />
      <BotonApuestaCombinacion101112131415 />
      <BotonApuestaCombinacion1314 />
      <BotonApuestaCombinacion1415 />
      <BotonApuestaCombinacion131415 />
      <BotonApuestaCombinacion1316 />
      <BotonApuestaCombinacion13141617 />
      <BotonApuestaCombinacion1417 />
      <BotonApuestaCombinacion14151718 />
      <BotonApuestaCombinacion1518 />
      <BotonApuestaCombinacion131415161718 />
      <BotonApuestaCombinacion1617 />
      <BotonApuestaCombinacion1718 />
      <BotonApuestaCombinacion161718 />
      <BotonApuestaCombinacion1619 />
      <BotonApuestaCombinacion16171920 />
      <BotonApuestaCombinacion1720 />
      <BotonApuestaCombinacion17182021 />
      <BotonApuestaCombinacion1821 />
      <BotonApuestaCombinacion161718192021 />
      <BotonApuestaCombinacion1920 />
      <BotonApuestaCombinacion2021 />
      <BotonApuestaCombinacion192021 />
      <BotonApuestaCombinacion1922 />
      <BotonApuestaCombinacion19202223 />
      <BotonApuestaCombinacion2023 />
      <BotonApuestaCombinacion20212324 />
      <BotonApuestaCombinacion2124 />
      <BotonApuestaCombinacion192021222324 />
      <BotonApuestaCombinacion2223 />
      <BotonApuestaCombinacion2324 />
      <BotonApuestaCombinacion222324 />
      <BotonApuestaCombinacion2225 />
      <BotonApuestaCombinacion22232526 />
      <BotonApuestaCombinacion2326 />
      <BotonApuestaCombinacion23242627 />
      <BotonApuestaCombinacion2427 />
      <BotonApuestaCombinacion222324252627 />
      <BotonApuestaCombinacion2526 />
      <BotonApuestaCombinacion2627 />
      <BotonApuestaCombinacion252627 />
      <BotonApuestaCombinacion2528 />
      <BotonApuestaCombinacion25262829 />
      <BotonApuestaCombinacion2629 />
      <BotonApuestaCombinacion26272930 />
      <BotonApuestaCombinacion2730 />
      <BotonApuestaCombinacion252627282930 />
      <BotonApuestaCombinacion2829 />
      <BotonApuestaCombinacion2930 />
      <BotonApuestaCombinacion282930 />
      <BotonApuestaCombinacion2831 />
      <BotonApuestaCombinacion28293132 />
      <BotonApuestaCombinacion2932 />
      <BotonApuestaCombinacion29303233 />
      <BotonApuestaCombinacion3033 />
      <BotonApuestaCombinacion282930313233 />
      <BotonApuestaCombinacion3132 />
      <BotonApuestaCombinacion3233 />
      <BotonApuestaCombinacion313233 />
      <BotonApuestaCombinacion3134 />
      <BotonApuestaCombinacion31323435 />
      <BotonApuestaCombinacion3235 />
      <BotonApuestaCombinacion32333536 />
      <BotonApuestaCombinacion3336 />
      <BotonApuestaCombinacion313233343536 />
      <BotonApuestaCombinacion3435 />
      <BotonApuestaCombinacion3536 />
      <BotonApuestaCombinacion343536 />
    </S.GrillaApuestas>
  );
};

export default GrillaApuestas;
