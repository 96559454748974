import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROLES } from '../../../../../../enums';
import { setIsDrawerOpen } from '../../../../../../redux/slices';
import { IAppStore } from '../../../../../../redux/store';
import { Button } from '../../../../../Button';

interface IProps {
  to?: string;
  rolRequerido?: number;
  children: React.ReactNode;
  onClick?: () => void;
}
const NavLink = ({ to, rolRequerido = 1, onClick, children }: IProps) => {
  const {
    usuario: { rol },
    ui: { isDrawerOpen },
  } = useSelector((store: IAppStore) => store);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const clickHandler = () => {
    if (onClick) onClick();
    if (isDrawerOpen) dispatch(setIsDrawerOpen(false));
    if (to) navigate(to);
  };

  const isActive = to === location.pathname;
  const tienePermisos = rolRequerido === 1 || rol === 2;

  if (!tienePermisos) {
    return null;
  }

  return (
    <Button isActive={isActive} onClick={clickHandler}>
      {children}
    </Button>
  );
};

export default NavLink;
