import { useState } from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Flex, Input, Select } from '../../components';
import { RUTAS } from '../../enums';
import { useUsuarios } from '../../hooks';
import * as S from './CrearCuenta.styled';

const niveles = [
  { descripcion: 'Bajo', codigo: 1 },
  { descripcion: 'Medio', codigo: 2 },
  { descripcion: 'Alto', codigo: 3 },
];

const roles = [
  { descripcion: 'Jugador', codigo: 1 },
  { descripcion: 'Administrador', codigo: 2 },
];

const CrearCuenta = () => {
  const { crearUsuario } = useUsuarios();
  const navigate = useNavigate();
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [usuario, setUsuario] = useState('');
  const [fichas, setFichas] = useState(0);
  const [nivel, setNivel] = useState(1);
  const [rol, setRol] = useState(1);
  const [contrasena, setContrasena] = useState('');

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.name === 'nombre' && setNombre(e.target.value);
    e.target.name === 'email' && setEmail(e.target.value);
    e.target.name === 'telefono' && setTelefono(e.target.value);
    e.target.name === 'usuario' && setUsuario(e.target.value);
    e.target.name === 'nivel' && setNivel(parseInt(e.target.value));
    e.target.name === 'contrasena' && setContrasena(e.target.value);
  };

  const handleCrear = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await crearUsuario({ nombre, email, telefono, usuario, fichas, nivel, rol: roles[rol].codigo, contrasena });
    borrarDatos();
  };

  const borrarDatos = () => {
    setNombre('');
    setEmail('');
    setTelefono('');
    setUsuario('');
    setFichas(0);
    setNivel(1);
    setRol(1);
    setContrasena('');
  };

  const changeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.target.name === 'nivel' && setNivel(niveles[e.target.selectedIndex].codigo);
    e.target.name === 'rol' && setRol(roles[e.target.selectedIndex].codigo);
  };

  const volver = () => {
    navigate(RUTAS.JUGADORES);
  };

  return (
    <S.CrearCuenta>
      <Card>
        <Flex display='flex' gap='1rem' flexDirection='column'>
          <Flex display='flex' flexDirection='row' alignItems='center' gap='1rem'>
            <BsArrowLeftCircle size={25} onClick={volver} title='Volver' />
            <S.Titulo>Crear jugador</S.Titulo>
          </Flex>
          <form onSubmit={handleCrear}>
            <Input id='nombre' name='nombre' placeholder='Nombre' value={nombre} onInput={inputHandler} autoFocus />
            <Input id='email' name='email' placeholder='Email' value={email} onInput={inputHandler} />
            <Input id='telefono' name='telefono' placeholder='Teléfono' value={telefono} onInput={inputHandler} />
            <Input id='usuario' name='usuario' placeholder='Usuario' value={usuario} onInput={inputHandler} />
            <Select id='nivel' name='nivel' onChange={changeHandler}>
              {niveles.map(nivel => (
                <option key={nivel.codigo} value={nivel.descripcion}>
                  {nivel.descripcion}
                </option>
              ))}
            </Select>
            <Select id='rol' name='rol' onChange={changeHandler}>
              {roles.map(rol => (
                <option key={rol.codigo} value={rol.codigo}>
                  {rol.descripcion}
                </option>
              ))}
            </Select>
            <Input
              id='contrasena'
              name='contrasena'
              placeholder='Contraseña'
              type='password'
              value={contrasena}
              onInput={inputHandler}
            />
            <Flex display='flex' gap='1rem' flexDirection='row'>
              <Button disabled={!contrasena} type='submit'>
                Crear jugador
              </Button>
              <Button onClick={borrarDatos}>Borrar datos</Button>
            </Flex>
          </form>
        </Flex>
      </Card>
    </S.CrearCuenta>
  );
};

export default CrearCuenta;
