import { COLORES } from '../enums';
import { INumero } from '../models';

export const numeros: INumero[] = [
  {
    color: COLORES.VERDE,
    valor: 0,
  },
  {
    color: COLORES.ROJO,
    valor: 1,
  },
  {
    color: COLORES.NEGRO,
    valor: 2,
  },
  {
    color: COLORES.ROJO,
    valor: 3,
  },
  {
    color: COLORES.NEGRO,
    valor: 4,
  },
  {
    color: COLORES.ROJO,
    valor: 5,
  },
  {
    color: COLORES.NEGRO,
    valor: 6,
  },
  {
    color: COLORES.ROJO,
    valor: 7,
  },
  {
    color: COLORES.NEGRO,
    valor: 8,
  },
  {
    color: COLORES.ROJO,
    valor: 9,
  },
  {
    color: COLORES.NEGRO,
    valor: 10,
  },
  {
    color: COLORES.NEGRO,
    valor: 11,
  },
  {
    color: COLORES.ROJO,
    valor: 12,
  },
  {
    color: COLORES.NEGRO,
    valor: 13,
  },
  {
    color: COLORES.ROJO,
    valor: 14,
  },
  {
    color: COLORES.NEGRO,
    valor: 15,
  },
  {
    color: COLORES.ROJO,
    valor: 16,
  },
  {
    color: COLORES.NEGRO,
    valor: 17,
  },
  {
    color: COLORES.ROJO,
    valor: 18,
  },
  {
    color: COLORES.ROJO,
    valor: 19,
  },
  {
    color: COLORES.NEGRO,
    valor: 20,
  },
  {
    color: COLORES.ROJO,
    valor: 21,
  },
  {
    color: COLORES.NEGRO,
    valor: 22,
  },
  {
    color: COLORES.ROJO,
    valor: 23,
  },
  {
    color: COLORES.NEGRO,
    valor: 24,
  },
  {
    color: COLORES.ROJO,
    valor: 25,
  },
  {
    color: COLORES.NEGRO,
    valor: 26,
  },
  {
    color: COLORES.ROJO,
    valor: 27,
  },
  {
    color: COLORES.NEGRO,
    valor: 28,
  },
  {
    color: COLORES.NEGRO,
    valor: 29,
  },
  {
    color: COLORES.ROJO,
    valor: 30,
  },
  {
    color: COLORES.NEGRO,
    valor: 31,
  },
  {
    color: COLORES.ROJO,
    valor: 32,
  },
  {
    color: COLORES.NEGRO,
    valor: 33,
  },
  {
    color: COLORES.ROJO,
    valor: 34,
  },
  {
    color: COLORES.NEGRO,
    valor: 35,
  },
  {
    color: COLORES.ROJO,
    valor: 36,
  },
];
