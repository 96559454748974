import { useEffect, useRef } from 'react';
import { useDialogContext } from '../../providers/DialogProvider';
import { Button } from '../Button';
import { Flex } from '../Flex';
import * as S from './Dialog.styled';

const Dialog = () => {
  const { dialog } = useDialogContext();
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    dialog ? dialogRef.current?.showModal() : dialogRef.current?.close();
  }, [dialog]);

  if (!dialog) return null;

  return (
    <S.Dialog ref={dialogRef}>
      <S.DialogCard>
        <Flex display='flex' flexDirection='column' gap='1rem' alignItems='center'>
          {dialog.messages?.length && dialog.messages.map((message, i) => <S.Messagges key={i}>{message}</S.Messagges>)}
          <Flex width='100%' display='flex' flexDirection='row' gap='1rem'>
            {dialog.buttons?.length &&
              dialog.buttons.map((button, i) => (
                <Button key={i} onClick={button.onClick}>
                  {button.text}
                </Button>
              ))}
          </Flex>
        </Flex>
      </S.DialogCard>
    </S.Dialog>
  );
};

export default Dialog;
