import styled from 'styled-components';
import entrada from '../../assets/entrada_bg.jpg';
import { color_ruby_300_value, pageStyle, primaryButtonStyle } from '../../styles';

export const Jugadores = styled.main`
  ${pageStyle}
  background-image: url(${entrada});
`;

export const Titulo = styled.h3`
  font-size: 2rem;
`;

export const CrearUsuario = styled.button`
  ${primaryButtonStyle};
`;

export const TablaJugadores = styled.section`
  background-color: ${color_ruby_300_value};
  padding: 0.5rem;
  border-radius: 4px;

  > table {
    width: 100%;

    thead {
      tr {
        th {
          padding: 0.5rem;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0.5rem;
        }
        text-align: center;
        svg {
          cursor: pointer;
        }
        > :last-child {
          display: flex;
          justify-content: center;
          gap: 1rem;
        }
      }
    }
  }
`;

export const AccionesCrearJugadores = styled.section``;
