import styled from 'styled-components';
import entrada from '../../assets/entrada_bg.jpg';
import { pageStyle } from '../../styles';

export const Sala = styled.main`
  ${pageStyle};
  position: relative;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content min-content;
  grid-template-areas:
    'ruleta'
    'timer'
    'fichero';
  background-image: url(${entrada});

  @media (width < 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content min-content;
    grid-template-areas:
      'ruleta'
      'timer'
      'fichero';
  }
`;
