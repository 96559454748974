import styled from 'styled-components';

export const DatosDeUsuario = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: white;
  text-align: center;

  > {
    margin: auto;
  }

  @media (width <= 1024px) {
    flex-direction: column;
  }
`;
