import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IUsuario } from '../models';
import { useDialogContext } from '../providers/DialogProvider';
import { setCargando } from '../redux/slices';
import { apiRuleta } from '../apis';

interface IData {
  success: boolean;
  payload: any;
}
export const useUsuarios = () => {
  const [data, setData] = useState<IData>({ success: false, payload: undefined });
  const { setDialog } = useDialogContext();
  const dispatch = useDispatch();

  const crearUsuario = async (usuario: Partial<IUsuario>) => {
    dispatch(setCargando(true));
    try {
      const response = await apiRuleta.post('/usuarios/crear', usuario);
      setDialog({
        messages: [response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } catch (e: any) {
      setDialog({
        messages: [e.response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } finally {
      dispatch(setCargando(false));
    }
  };

  const getUsuarioPorUsername = async (username: string) => {
    dispatch(setCargando(true));
    try {
      const response = await apiRuleta.post('/usuarios/buscar', { username });
      setData(response.data);
    } catch (e: any) {
      setDialog({
        messages: [e.response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } finally {
      dispatch(setCargando(false));
    }
  };

  const getUsuarios = async () => {
    dispatch(setCargando(true));
    try {
      const response = await apiRuleta.get('/usuarios/todos');
      setData(response.data);
    } catch (e: any) {
      setDialog({
        messages: [e.response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } finally {
      dispatch(setCargando(false));
    }
  };

  const actualizarUsuario = async (usuario: Partial<IUsuario>) => {
    dispatch(setCargando(true));
    try {
      const response = await apiRuleta.post('/usuarios/actualizar', usuario);
      setData(response.data);
      setDialog({
        messages: [response.data.payload],
        buttons: [
          {
            text: 'Continuar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } catch (e: any) {
      setDialog({
        messages: [e.response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } finally {
      dispatch(setCargando(false));
    }
  };

  const borrarUsuarioPorId = async (_id: string) => {
    dispatch(setCargando(true));
    try {
      const response = await apiRuleta.post('/usuarios/borrar', { _id });
      setDialog({
        messages: [response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } catch (e: any) {
      setDialog({
        messages: [e.response?.data?.payload],
        buttons: [
          {
            text: 'Aceptar',
            onClick: () => setDialog(null),
          },
        ],
      });
    } finally {
      dispatch(setCargando(false));
    }
  };

  return { crearUsuario, getUsuarioPorUsername, getUsuarios, actualizarUsuario, borrarUsuarioPorId, data };
};
