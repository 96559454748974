import { useParticipant } from '@videosdk.live/react-sdk';
import { useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';
import * as S from './RuletaLiveStreaming.styled';

interface IProps {
  id: string;
}
const RuletaLiveStreaming = ({ id }: IProps) => {
  const { webcamStream, setQuality } = useParticipant(id);

  useEffect(() => setQuality('high'), [setQuality]);

  const videoStream = useMemo(() => {
    if (webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream]);

  return (
    <S.RuletaLiveStreaming>
      {videoStream && (
        <ReactPlayer
          playsinline
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          onError={err => {
            console.log(err, 'participant video error');
          }}
        />
      )}
    </S.RuletaLiveStreaming>
  );
};

export default RuletaLiveStreaming;
