import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Logo } from '../';
import { setIsDrawerOpen } from '../../redux/slices';
import { IAppStore } from '../../redux/store';
import * as S from './Header.styled';
import { Burguer, Navegador } from './components';

const Header = () => {
  const { conectado } = useSelector((store: IAppStore) => store.usuario);
  const { isDrawerOpen } = useSelector((store: IAppStore) => store.ui);
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const event = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', event);
    return () => window.removeEventListener('resize', event);
  }, []);

  useEffect(() => {
    if (isDrawerOpen && width >= 1024) dispatch(setIsDrawerOpen(false));
  }, [isDrawerOpen, width, dispatch]);

  const clickHandler = () => {
    dispatch(setIsDrawerOpen(!isDrawerOpen));
  };

  if (!conectado) return null;

  return (
    <S.Header>
      <Logo height='75px' />
      <Navegador isOpen={isDrawerOpen} />
      <Burguer />
      {isDrawerOpen && <S.Backdrop onClick={clickHandler} />}
    </S.Header>
  );
};

export default Header;
