import styled from 'styled-components';
import { pageStyle } from '../../styles';

export const Loader = styled.div`
  ${pageStyle};
  width: 100%;
  height: 100%;
  background: radial-gradient(black, transparent 70%);
  position: absolute;
  left: 0;
  top: 0;
`;
