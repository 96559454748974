import styled from 'styled-components';
import { color_black_100_value } from '../../styles';

export const Header = styled.header`
  padding: 0.5rem 1rem;
  background-color: ${color_black_100_value};
  display: flex;
  justify-content: space-between;

  /* @media (width < 720px) {
    display: flex;
    justify-content: space-between;
  } */
`;

export const DatosDeUsuario = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const Acciones = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
`;
