import * as S from './BotonIniciarSesion.styled';

interface IProps {
  disabled?: boolean;
}
const BotonIniciarSesion = ({ disabled = false }: IProps) => {
  return (
    <S.BotonIniciarSesion>
      <button disabled={disabled}>Acceder</button>
    </S.BotonIniciarSesion>
  );
};

export default BotonIniciarSesion;
