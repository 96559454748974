import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RUTAS } from '../../enums';
import { useSocket } from '../../hooks';
import { IAppStore } from '../../redux/store';

const GuardiaAutenticacion = () => {
  const { conectado } = useSelector((store: IAppStore) => store.usuario);
  useSocket();

  return conectado ? <Outlet /> : <Navigate to={RUTAS.ACCESO} />;
};

export default GuardiaAutenticacion;
