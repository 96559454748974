import styled from 'styled-components';
import { drawerStyle } from '../../../../styles';

interface INavegadorProps {
  isOpen: boolean;
}

export const Navegador = styled.nav<INavegadorProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 100;

  @media (width <= 1024px) {
    display: none;
    ${({ isOpen }) => isOpen && `${drawerStyle}`};
  }
`;
