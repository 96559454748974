import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DialogType = {
  messages?: string[];
  buttons?: {
    text: string;
    onClick: () => void;
  }[];
};

export interface IUIState {
  cargando: boolean;
  dialog: DialogType | undefined;
  isDrawerOpen: boolean;
}

const initialState: IUIState = {
  cargando: false,
  dialog: undefined,
  isDrawerOpen: false,
};

const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setCargando: (state, action) => {
      state.cargando = action.payload;
    },
    setDialog: (state, action: PayloadAction<DialogType | undefined>) => {
      state.dialog = action.payload;
    },
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
  },
});

export default uiSlice;
export const { setCargando, setDialog, setIsDrawerOpen } = uiSlice.actions;
