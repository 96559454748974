import * as S from './Sala.styled';
import { Fichero, Pano, Ruleta, Timer } from './components';

const Sala = () => {
  return (
    <S.Sala>
      <Ruleta>
        <Pano />
      </Ruleta>
      <Timer />
      <Fichero />
      {/* <Chat /> */}
    </S.Sala>
  );
};

export default Sala;
