import { useDispatch, useSelector } from 'react-redux';
import { IFicha } from '../../../../../../models';
import { seleccionarFicha } from '../../../../../../redux/slices';
import { IAppStore } from '../../../../../../redux/store';
import * as S from './Ficha.styled';

interface IProps {
  ficha: IFicha;
}
const Ficha = ({ ficha: { valor, label } }: IProps) => {
  const { fichaSeleccionada } = useSelector((store: IAppStore) => store.usuario);
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(seleccionarFicha(valor));
  };

  return (
    <S.Ficha id={`ficha-${valor}`} onClick={clickHandler} estaSeleccionada={valor === fichaSeleccionada}>
      {label}
    </S.Ficha>
  );
};

export default Ficha;
