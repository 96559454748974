import { useNavigate } from 'react-router-dom';
import ingresar_sala from '../../assets/ingresar_sala.webp';
import { ESTADOS, RUTAS } from '../../enums';
import { useTimerContext } from '../../providers/TimerProvider';
import * as S from './Lobby.styled';

const Lobby = () => {
  const navigate = useNavigate();
  const { estado } = useTimerContext();

  const onClick = () => {
    console.log('estado', estado);
    if (estado === ESTADOS.EN_ESPERA) navigate(RUTAS.SALA);
  };

  return (
    <S.Lobby>
      <S.Img onClick={onClick} src={ingresar_sala} alt='Acceder a la sala' title='Acceder a la sala' />
    </S.Lobby>
  );
};

export default Lobby;
