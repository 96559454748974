import styled from 'styled-components';
import { color_ruby_100_value, color_white_100_value, pageStyle } from '../../styles';
import entrada from './../../assets/entrada_bg.jpg';

export const Acceso = styled.main`
  ${pageStyle};
  background-image: url(${entrada});
`;

export const Formulario = styled.form`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: radial-gradient(#e66465, #9198e5, transparent 65%);

  @media (max-width: 376px) {
    max-width: 80%;
  }

  @media (min-width: 377px) {
    max-width: 50%;
  }

  @media (min-width: 1024px) {
    max-width: 40%;
  }

  @media (min-width: 1024px) {
    max-width: 30%;
  }
`;

export const Error = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid ${color_ruby_100_value};
  margin: auto;
  color: ${color_white_100_value};
  opacity: 90%;
  background-color: black;
  text-align: center;
`;

export const Cargando = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid ${color_ruby_100_value};
  margin: auto;
  color: ${color_white_100_value};
  opacity: 90%;
  background-color: black;
  text-align: center;
`;
