import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ESTADOS } from '../enums';
import { setJuegoState } from '../redux/slices';
import { IAppStore } from '../redux/store';

type EstadoTimer = ESTADOS.EN_CURSO | ESTADOS.EN_PAUSA | ESTADOS.EN_ESPERA;

type StateType = {
  estado: EstadoTimer;
  tiempoRestante: number;
  setEnCurso: () => void;
  setEnPausa: () => void;
  setEnEspera: () => void;
};

const initialState: StateType = {
  estado: ESTADOS.EN_ESPERA,
  tiempoRestante: 40,
  setEnCurso: () => {},
  setEnPausa: () => {},
  setEnEspera: () => {},
};

export const TimerContext = createContext(initialState);

interface TimerProviderProps {
  children: React.ReactNode;
}
const TimerProvider = ({ children }: TimerProviderProps) => {
  const { estado } = useSelector((store: IAppStore) => store.juego);
  const dispatch = useDispatch();
  const [tiempoRestante, setTiempoRestante] = useState(initialState.tiempoRestante);

  useEffect(() => {
    // if (estado === ESTADOS.EN_ESPERA) return;
    let interval: NodeJS.Timer;
    if (estado === ESTADOS.EN_CURSO && tiempoRestante > 0) {
      interval = setInterval(() => {
        setTiempoRestante(tiempoRestante => tiempoRestante - 1);
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [estado, tiempoRestante]);

  const setEnCurso = () => {
    dispatch(setJuegoState({ estado: ESTADOS.EN_CURSO }));
    if (tiempoRestante === 0) setTiempoRestante(initialState.tiempoRestante);
  };

  const setEnPausa = () => {
    dispatch(setJuegoState({ estado: ESTADOS.EN_PAUSA }));
  };

  const setEnEspera = () => {
    dispatch(setJuegoState({ estado: ESTADOS.EN_ESPERA }));
    setTiempoRestante(initialState.tiempoRestante);
  };

  return (
    <TimerContext.Provider
      value={{
        estado,
        tiempoRestante,
        setEnCurso,
        setEnPausa,
        setEnEspera,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
export const useTimerContext = () => useContext(TimerContext);
