import styled from 'styled-components';
import { color_black_100_value, color_ruby_100_value, color_white_100_value, iconStyle } from '../../../../styles';

export const Chat = styled.section`
  position: absolute;
  top: 50%;

  svg {
    ${iconStyle};
  }

  > div {
    width: min-content;
    padding: 1rem;
    border: solid ${color_ruby_100_value};
    border-width: 1px 1px 1px 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${color_black_100_value};
    color: ${color_white_100_value};
  }
`;
