import { INumero } from '../../../../../../../../models';
import * as S from './Numero.styled';

interface IProps {
  numero: INumero;
}
const Numero = ({ numero }: IProps) => {
  return <S.Numero numero={numero}>{`${numero.valor}`}</S.Numero>;
};

export default Numero;
