import { useTimerContext } from '../../../../providers/TimerProvider';
import * as S from './Timer.styled';

const Timer = () => {
  const { tiempoRestante } = useTimerContext();

  return <S.Timer>{tiempoRestante}</S.Timer>;
};

export default Timer;
