import { numeros } from '../../../../../../data';
import { Numero } from './components';

const SistemaDeNumeros = () => {
  return (
    <>
      {numeros.map(numero => (
        <Numero key={numero.valor} numero={numero} />
      ))}
    </>
  );
};

export default SistemaDeNumeros;
