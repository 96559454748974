import { RUTAS } from '../../enums';
import * as S from './Logo.styled';
import { useNavigate } from 'react-router-dom';
import ruby_logo from '../../assets/ruby_logo.png';

interface IProps {
  centro?: boolean;
  height?: string;
}
const Logo = ({ centro, height }: IProps) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(RUTAS.LOBBY);
  };

  return (
    <S.Logo centro={centro} height={height}>
      <img height={height} onClick={clickHandler} src={ruby_logo} alt='Rubí Ruleta' />
    </S.Logo>
  );
};

export default Logo;
