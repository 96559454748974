import { useContext } from 'react';
import { numeros } from '../../../../../../../../data';
import { INumero } from '../../../../../../../../models';
import { DialogContext } from '../../../../../../../../providers/DialogProvider';
import { socketService } from '../../../../../../../../services';
import { Numero } from './components';

const SistemaNumerosParaServir = () => {
  const { setDialog } = useContext(DialogContext);

  const clickHandler = (numero: INumero) => {
    setDialog({
      messages: [`Está seguro que desea servir el número ${numero.valor}?`],
      buttons: [
        {
          onClick: () => {
            setDialog(null);
          },
          text: 'Cancelar',
        },
        {
          onClick: () => {
            socketService.servirNumero(numero);
            setDialog(null);
          },
          text: 'Aceptar',
        },
      ],
    });
  };

  return (
    <>
      {numeros.map(numero => (
        <Numero key={numero.valor} numero={numero} onClick={clickHandler} />
      ))}
    </>
  );
};

export default SistemaNumerosParaServir;
