import styled from 'styled-components';

export const Fichero = styled.section`
  display: flex;
  grid-area: fichero;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
  }
`;
