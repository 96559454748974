import styled from 'styled-components';
import { color_black_100_value, color_ruby_100_value, color_white_100_value } from '../../styles';

export const Dialog = styled.dialog`
  background-color: transparent;
  border: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  ::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const DialogCard = styled.section`
  min-width: 40vw;
  padding: 1rem;
  border: 1px solid ${color_ruby_100_value};
  border-radius: 10px;
  background-color: ${color_black_100_value};
  color: ${color_white_100_value};
`;

export const Messagges = styled.section`
  width: 100%;
  text-align: center;
`;
