import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { IAppStore } from '../../redux/store';
import { color_ruby_100_value, color_ruby_300_value } from '../../styles';
import * as S from './Loader.styled';

const Loader = () => {
  const { cargando } = useSelector((store: IAppStore) => store.ui);

  return cargando ? (
    <S.Loader>
      <Oval
        height='80'
        width='80'
        color={color_ruby_100_value}
        secondaryColor={color_ruby_300_value}
        ariaLabel='oval-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </S.Loader>
  ) : null;
};

export default Loader;
