import styled from 'styled-components';
import { primaryButtonStyle } from '../../../../styles';

export const BotonIniciarSesion = styled.div`
  display: flex;
  justify-content: center;

  > button {
    ${primaryButtonStyle}
  }

  > button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;
