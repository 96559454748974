import styled from 'styled-components';
import { defaultActiveStyle } from '../../../../../../styles';

export const PanoServirNumero = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(13, 5vh);
  grid-template-rows: repeat(3, 5vh);
  grid-template-areas:
    'numero-cero  numero-3  numero-6  numero-9  numero-12  numero-15  numero-18  numero-21  numero-24  numero-27  numero-30  numero-33  numero-36'
    'numero-cero  numero-2  numero-5  numero-8  numero-11  numero-14  numero-17  numero-20  numero-23  numero-26  numero-29  numero-32  numero-35'
    'numero-cero  numero-1  numero-4  numero-7  numero-10  numero-13  numero-16  numero-19  numero-22  numero-25  numero-28  numero-31  numero-34';
  gap: 4px;
  user-select: none;

  > span {
    padding: 0.5rem;
    cursor: pointer;
    transition: 300ms;

    ${defaultActiveStyle};
    :hover {
      scale: 1.3;
      border: 1px solid white;
    }
  }

  @media (width < 1024px) {
    grid-template-columns: repeat(3, 5vh);
    grid-template-rows: repeat(13, 5vh);
    grid-template-areas:
      'numero-cero numero-cero numero-cero'
      'numero-1    numero-2    numero-3'
      'numero-4    numero-5    numero-6'
      'numero-7    numero-8    numero-9'
      'numero-10   numero-11   numero-12'
      'numero-13   numero-14   numero-15'
      'numero-16   numero-17   numero-18'
      'numero-19   numero-20   numero-21'
      'numero-22   numero-23   numero-24'
      'numero-25   numero-26   numero-27'
      'numero-28   numero-29   numero-30'
      'numero-31   numero-32   numero-33'
      'numero-34   numero-35   numero-36';
  }
`;
