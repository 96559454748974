import { useMeeting } from '@videosdk.live/react-sdk';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setJuegoState } from '../../../../redux/slices';
import { RuletaLiveStreaming } from './components';
import * as S from './Ruleta.styled';

interface IProps {
  children: React.ReactNode;
}
const Ruleta = ({ children }: IProps) => {
  const { participants, localParticipant, join } = useMeeting();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localParticipant) join();
    // enviar el id de la meeting al backend
    // el backend envia el id de la meeting a todos los usuarios
    // se settea en el store
    // al momento de entrar a la sala, se carga el streaming con el id de la meeting guardada
  }, [localParticipant, join]);

  // useEffect(() => {
  //   const jugadores = [...participants.keys()];
  //   console.log(jugadores);
  //   dispatch(setJuegoState({ jugadores }));
  // }, [dispatch, participants]);

  return (
    <S.Ruleta>
      {localParticipant && <RuletaLiveStreaming id={localParticipant.id} />}
      {children}
    </S.Ruleta>
  );
};

export default Ruleta;
