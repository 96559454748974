import styled from 'styled-components';
import { primaryButtonStyle } from '../../../../styles';

export const AccionesSala = styled.div``;

export const Sala = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ConectarSala = styled.button`
  ${primaryButtonStyle};
`;

export const AbrirCamara = styled.button`
  ${primaryButtonStyle};

  /* button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  } */
`;
