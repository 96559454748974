import { useEffect, useState } from 'react';
import * as S from './FichaToken.styled';

interface IProps {
  fichasApostadas: number;
}
const FichaToken = ({ fichasApostadas }: IProps) => {
  const [animar, setAnimar] = useState(false);

  useEffect(() => {
    reproducirAnimacion();
  }, []);

  const clickHandler = () => {
    reproducirAnimacion();
  };

  const reproducirAnimacion = () => {
    setAnimar(true);
    setTimeout(() => setAnimar(false), 400);
  };

  return (
    <S.FichaToken animar={animar} fichasApostadas={fichasApostadas} onClick={clickHandler}>
      {fichasApostadas}
    </S.FichaToken>
  );
};

export default FichaToken;
