import styled from 'styled-components';

export const Timer = styled.section`
  grid-area: timer;
  padding: 0.5rem;
  width: 100%;
  color: white;
  text-align: center;
  background: radial-gradient(circle, green 7%, rgba(0, 255, 85, 0) 30%);
  z-index: 0;
`;
