import styled from 'styled-components';

export const NavLinks = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (width > 1024px) {
    flex-direction: row;
  }
`;
