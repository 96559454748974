import styled from 'styled-components';
import { COLORES } from '../../../../../../../../../../enums';
import { INumero } from '../../../../../../../../../../models';
import { numeroNegroStyle, numeroRojoStyle, numeroVerdeStyle } from '../../../../../../../../../../styles';

interface INumeroProps {
  numero: INumero;
}
export const Numero = styled.span<INumeroProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: ${({ numero }: INumeroProps) => (numero.valor === 0 ? 'numero-cero' : `numero-${numero.valor}`)};

  ${({ numero }: INumeroProps) =>
    numero.color === COLORES.VERDE
      ? `${numeroVerdeStyle}`
      : numero.color === COLORES.ROJO
      ? `${numeroRojoStyle}`
      : `${numeroNegroStyle}`};
`;
